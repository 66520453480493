<template>
    <div>
        <div class="breadCrumb" style="display: flex;">
            <div @click="tabBreadCrumb(index)" v-for="(item, index) in breadCrumb" :key="index"
                :class="index == bread_crumb_selected ? 'bread_crumb_item_selected' : 'bread_crumb_item'">
                {{ item.name }}
            </div>
        </div>
        <div class="wrapper">
            <!-- <div class="header" v-if="bread_crumb_selected !== 3">
                <div v-if="bread_crumb_selected == 0"
                    style="display: flex;margin-top: 2px;margin-right: 10px;align-items: center;justify-content: space-between;width: 100%;">
                    <div style="display: flex;align-items: center;">
                        <div class="mybtn" style="margin-right: 10px;">添加商家</div>

                    </div>

                </div>
                <div v-if="bread_crumb_selected == 1" style="display: flex;margin-top: 2px;margin-right: 10px;">
                    <div @click="businesses_info_create" class="mybtn" style="">+ 添加</div>
                </div>
            </div> -->

            <div class="body">
                <div v-if="bread_crumb_selected == 0" style="padding: 10px;">
                    <div v-if="busy_time.length == 0 && !showLoading"
                        style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
                        暂无
                    </div>
                    <Table :fields="fields" :data="busy_time" :searchValue="searchValue" :loadAll="true"
                        @scroll="table_scrollEvent">
                        <template v-slot:times="cell">
                            <div style="width:100%">
                                <div style="float: left;margin: 0 8px 8px 0;" v-for="(times_item, index) in cell.value"
                                    :key="index">
                                    <div @click="showDropdown(times_item.id)"
                                        style="padding:6px 10px;border:1px solid #ccc;cursor: pointer;border-radius: 1px;font-size: 15px;position: relative;"
                                        :style="{background:times_item.busy?'#d20a0a':'',color:times_item.busy?'#fff':'#333'}">
                                        {{ times_item.time}}
                                        <div v-if="times_item.id==dropdown_id"
                                            style="position: absolute;top: 40px;left: 0;background-color: #fff;z-index: 888;padding: 10px;box-sizing: border-box;border: 1px solid #888;border-radius: 3px;">
                                            <div
                                                style="height:200px;background-color: #fff;width: 190px;overflow-y: scroll;">
                                                <div v-for="(coverArea_item, index2) in times_item.coverArea"
                                                    :key="index2" @click="setBusy(coverArea_item,times_item,cell.item)"
                                                    style="float: left;">
                                                    <div style="padding:6px 10px;cursor: pointer;border-radius: 1px;min-width: 70px;display: flex;align-items: center;justify-content: center;
                                                user-select: none;font-size: 14px;
                                                margin-bottom: 6px;"
                                                        :style="{marginRight:index2%2==0?'12px':'',color:coverArea_item.busy?'#fff':'#000',background:coverArea_item.busy?'#d20a0a':'#fff'}"
                                                        class="border-red">
                                                        {{ coverArea_item.city }}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style="display: flex;align-items: center;padding:10px 10px 0 0;">
                                                <div @click="setAllBusy(times_item,cell.item)" class="mybtn flex-btn">
                                                    全选</div>
                                                <div @click="clearAllBusy(times_item,cell.item)" class="mybtn flex-btn">
                                                    清空</div>
                                                <div @click="hideDropdown(times_item.id)" class="mybtn flex-btn">
                                                    关闭</div>
                                            </div>

                                        </div>
                                    </div>


                                </div>
                            </div>

                        </template>
                        <template v-slot:operate="cell">
                            <div>
                                <div @click="showDropdown(('r-' + cell.item.id))"
                                    style="width:60px;padding:6px 10px;border:1px solid #ccc;cursor: pointer;border-radius: 1px;font-size: 15px;position: relative;"
                                    :style="{background:cell.item.selectedAll?'#d20a0a':'',color:cell.item.selectedAll?'#fff':'#333'}">
                                    全选
                                    <div v-if="('r-' + cell.item.id)==dropdown_id"
                                        style="position: absolute;top: 40px;right: 0;background-color: #fff;z-index: 888;padding: 10px;box-sizing: border-box;border: 1px solid #888;border-radius: 3px;">
                                        <div
                                            style="height:200px;background-color: #fff;width: 190px;overflow-y: scroll;">
                                            <div v-for="(coverArea_item, index2) in cell.item.coverArea" :key="index2"
                                                @click="setBusyCity(coverArea_item,cell.item)" style="float: left;">
                                                <div style="padding:6px 10px;cursor: pointer;border-radius: 1px;min-width: 70px;display: flex;align-items: center;justify-content: center; user-select: none;font-size: 14px;margin-bottom: 6px;"
                                                    :style="{marginRight:index2%2==0?'12px':'',color:coverArea_item.busy?'#fff':'#000',background:coverArea_item.busy?'#d20a0a':'#fff'}"
                                                    class="border-red">
                                                    {{ coverArea_item.city }}
                                                </div>
                                            </div>
                                        </div>
                                        <div style="display: flex;align-items: center;padding:10px 10px 0 0;">
                                            <div @click="setBusyCityAll(cell.item,cell.item.date)"
                                                class="mybtn flex-btn">
                                                全选</div>
                                            <div @click="clearBusyCityAll(cell.item,cell.item.date)"
                                                class="mybtn flex-btn">
                                                清空</div>
                                            <div @click="hideDropdown(cell.item.id)" class="mybtn flex-btn">
                                                关闭</div>
                                        </div>
                                    </div>
                                </div>
                            </div>




                        </template>
                    </Table>
                </div>

                <div v-if="showLoading"
                    style="display: flex;align-items: center;justify-content: center;padding: 20px 0;background-color: #fff;">
                    <img style="margin-right: 5px;" src="../assets/loading.gif" alt="">加载中..
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    const utils = require('../utils/utils')
    import {
        BModal
    } from 'bootstrap-vue'
    import Modal from '../components/Modal/Modal'
    import Table from '../components/Table/Table'
    import Select from '../components/Select/Select'

    export default {
        name: 'Order',
        components: {
            'b-modal': BModal,
            'Modal': Modal,
            'Table': Table,
            'Select': Select,
        },
        data() {
            return {
                tab: [{
                        name: '添加师傅',
                        status: 0,
                        count: 0
                    },
                    {
                        name: '邀请师傅',
                        status: 0,
                        count: 0
                    }
                ],
                breadCrumb: [{
                    name: '店铺忙时设置'
                }, ],
                bread_crumb_selected: 0,
                selected: 0,
                showLoading: false,
                preview_images: '',
                showPreviewImages: false,
                remarks: '',
                busy_time: [],
                dropdown_id: null,
                fields: [{
                        key: 'strDate',
                        label: '日期',
                        width: '175px'
                    },
                    {
                        key: 'times',
                        label: '可预约时间',
                    },
                    {
                        key: 'operate',
                        label: '操作',
                        width: '170px'
                    }
                ],
                selected_cities: null,
                searchValue: null,
                map_selected_cities: null,
                cities: [],
                coverArea: [],
                merchantData: {},
                loadAll: false
            };
        },
        mounted() {
            // 组件挂载完成回调，处理逻辑
            this.getData()

        },
        methods: {
            tabClick(index) {
                this.selected = index
            },
            tabBreadCrumb(index) {
                this.bread_crumb_selected = index
            },
            getData() {
                this.goodsList = [];
                this.showLoading = true;
                this.post('busy_time_get', {}).then((res) => {
                    console.log('res', res)
                    this.coverArea = res.coverArea
                    res.busy_time.forEach((item, index) => {
                        if (typeof item.times == 'string') {
                            item.times = JSON.parse(item.times)
                        }
                        item.times.forEach(times_item => {

                            // 字符串转数组
                            times_item.city = times_item.city ? times_item
                                .city.split(',') : []
                        })
                        this.calcItem(item)
                    })
                    res.busy_time.forEach((item, index) => {
                        this.reFreshData(item, this.coverArea)
                    })
                    this.busy_time = res.busy_time;


                    console.log('sssss', this.busy_time, )
                    this.showLoading = false
                })

            },
            calcItem(item) {
                let cityArr = []
                item.times.forEach(times_item => {
                    // 汇总城市
                    cityArr = cityArr.concat(times_item.city)

                })
                const count = cityArr.reduce((acc, city) => {
                    if (city in acc) {
                        acc[city]++;
                    } else {
                        acc[city] = 1;
                    }
                    return acc;
                }, {});
                item.city = Object.entries(count).map(e => {
                    if (e[1] == item.times.length) {
                        return e[0]
                    }
                }).filter(e => e)
            },
            reFreshData(item, coverArea) {

                let item_city = item.city
                item.coverArea = coverArea.map(item => {
                    return {
                        city: item,
                        busy: item_city.indexOf(item) !== -1
                    }
                })

                item.times.forEach((times_item, index2) => {
                    let all = times_item.city.concat(item_city)
                    if (index2 == 0) {
                        console.log('qqwesad', times_item.city.concat(item_city))
                    }
                    times_item.busy = all.length > 0
                    times_item.coverArea = coverArea.map(item => {
                        return {
                            city: item,
                            busy: all.indexOf(item) !== -1
                        }
                    })
                })


            },

            selectAll(item) {
                console.log(item)

            },
            showDropdown(id) {
                console.log('改变', id)
                this.dropdown_id = id;
            },
            hideDropdown(dropdown_id) {
                console.log('关闭', this.dropdown_id)
                setTimeout(() => {
                    this.dropdown_id = null
                }, 0);
            },
            setBusy(coverArea_item, times_item, busy_item) {
                coverArea_item.busy = !coverArea_item.busy
                let city = coverArea_item.city
                let busy = coverArea_item.busy
                times_item.busy = times_item.coverArea.filter(item => item.busy).length
                if (busy) {
                    times_item.city.push(city)
                } else {
                    times_item.city = times_item.city.filter(e => e !== city)
                }
                let DateTimestamp = busy_item.DateTimestamp
                this.busy_time.forEach(item => {
                    if (item.DateTimestamp == DateTimestamp) {
                        setTimeout(() => {
                            this.calcItem(item)
                            this.reFreshData(item, this.coverArea)
                        }, 1000);
                    }
                })


                this.post('busy_time_set', {
                    city,
                    busy,
                    // time,
                    // day,
                    timestamp: times_item.timestamp
                }).then((res) => {
                    console.log('res', res)
                })
            },
            setAllBusy(times_item, busy_item) {
                times_item.coverArea.forEach(item => item.busy = true)
                times_item.busy = true
                times_item.city = this.coverArea
                let DateTimestamp = busy_item.DateTimestamp
                this.busy_time.forEach(item => {
                    if (item.DateTimestamp == DateTimestamp) {
                        setTimeout(() => {
                            this.calcItem(item)
                            this.reFreshData(item, this.coverArea)
                        }, 1000);
                    }
                })


                this.post('busy_time_set', {
                    city: null,
                    busy: true,
                    timestamp: times_item.timestamp
                }).then((res) => {
                    console.log('res', res)
                })
            },

            clearAllBusy(times_item, busy_item) {
                times_item.coverArea.forEach(item => item.busy = false)
                times_item.busy = false

                times_item.city = []
                let DateTimestamp = busy_item.DateTimestamp
                this.busy_time.forEach(item => {
                    if (item.DateTimestamp == DateTimestamp) {
                        setTimeout(() => {
                            this.calcItem(item)
                            this.reFreshData(item, this.coverArea)
                        }, 1000);
                    }
                })


                this.post('busy_time_set', {
                    city: null,
                    busy: false,
                    timestamp: times_item.timestamp
                }).then((res) => {
                    console.log('res', res)
                })
            },

            setBusyCity(coverArea_item, busy_item) {
                coverArea_item.busy = !coverArea_item.busy;
                let DateTimestamp = busy_item.DateTimestamp;
                let city = coverArea_item.city
                let busy = coverArea_item.busy
                console.log('选择城市', city, busy)

                this.busy_time.forEach(item => {
                    if (item.DateTimestamp == DateTimestamp) {
                        console.log(item)
                        if (busy) {
                            console.log('添加')
                            item.city = [...new Set(item.city)]
                            item.city.push(city)
                        } else {
                            item.city = item.city.filter(item => item !== city)
                            item.times.forEach(times_item => {
                                times_item.city = times_item.city.filter(item => item !== city)
                            })
                            console.log('去除', item.city)
                        }

                        this.reFreshData(item, this.coverArea)
                    }
                })



                this.post('busy_time_date_set', {
                    city,
                    busy,
                    DateTimestamp: DateTimestamp
                }).then((res) => {
                    // this.getData()
                })
            },
            setBusyCityAll(busy_item) {
                let DateTimestamp = busy_item.DateTimestamp;

                this.post('busy_time_date_set', {
                    city: null,
                    busy: true,
                    DateTimestamp: DateTimestamp
                }).then((res) => {
                    this.getData()
                })
            },
            clearBusyCityAll(busy_item) {
                let DateTimestamp = busy_item.DateTimestamp;

                this.post('busy_time_date_set', {
                    city: null,
                    busy: false,
                    DateTimestamp: DateTimestamp
                }).then((res) => {
                    this.getData()
                })
            },


        }
    }
</script>
<style scoped>
    .flex-btn {
        flex: 1;
        margin-right: 5px;
        justify-content: center;
        display: flex;
    }

    .border-red {
        border: 1px solid #ccc;
    }

    .border-red:hover {
        border: 1px solid #d20a0a;
    }

    .wrapper {
        color: #333;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #dddddd;
    }

    .header {
        background-color: #f5f5f5;
        border-bottom: 1px solid #dddddd;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        padding: 10px 15px;
        font-size: 12.5px;
        font-family: "Microsoft YaHei", SimSun;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #dddddd;
    }

    .bread_crumb_item {
        cursor: pointer;
        border-bottom: 2px solid #fff;
        padding: 10px 15px 8px 15px;
        color: #206bc4;
        font-size: 14px;
        margin-right: 4px;

    }

    .bread_crumb_item_selected {
        cursor: pointer;
        border-bottom: 2px solid #206bc4;
        padding: 10px 15px 8px 15px;
        border-radius: 5px 5px 0 0;
        color: #fff;
        background: #206bc4;
        font-size: 14px;
        margin-right: 4px;

    }

    .bread_crumb_item_selected:hover {
        background: #206bc4;
    }

    .bread_crumb_item:hover {
        background: #eeeeee;
    }

    .body {}

    .tab {
        background-color: white;
        padding: 5px 10px;
        font-size: 11.5px;
        cursor: pointer;
        touch-action: manipulation;
        user-select: none;

    }

    .tab-select {
        color: white;
        background-color: #1f68be;
    }

    .tab:hover {
        background: #e6e6e6;
        color: black;
    }

    .tab:active {
        background: #e6e6e6;
        color: black;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }

    .tab-border-left {
        border-left: 1px solid #cccccc;
    }

    .mybtn {
        background-color: #2c72b0;
        padding: 5px 10px;
        font-size: 11.5px;
        cursor: pointer;
        touch-action: manipulation;
        color: #ffffff;
        user-select: none;
        border-radius: 3px;
    }

    .mybtn:hover {
        background-color: #286090;
        color: #ffffff;
    }

    .mybtn:active {
        background-color: #1d5280;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }


    .btn2 {
        background-color: #e8e8e8;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        padding: 5px 10px;
        font-size: 11.5px;
        cursor: pointer;
        touch-action: manipulation;
        border: 1px solid #555555;
        color: #000000;
        border-radius: 3px;
        user-select: none;
    }

    .btn2:hover {
        background-color: #cccccc;
    }

    .btn2:active {
        background-color: #aaaaaa;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }



    .btn3 {
        background-color: #f0ad4e;
        padding: 8px 17px;
        font-size: 14px;
        cursor: pointer;
        touch-action: manipulation;
        color: #ffffff;
        border-radius: 3px;
    }

    .btn3:hover {
        background-color: #e09b3c;
        color: #ffffff;
    }

    input {
        outline: none;
        height: 30px;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        border: 1px solid #cccccc;
    }

    input:focus {
        border: 1px solid rgb(56, 115, 192) !important;
    }

    .float {
        /* float: left; */
        /* min-width: 25%; */
        height: 100%;
        display: flex;
        padding: 10px 0;
    }


    .btn8 {
        position: absolute;
        right: 50px;
        bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #206bc4;
        z-index: 888;
        color: white;
        padding: 10px 30px;
        border-radius: 7px;
        cursor: pointer;
        font-size: 17px;
    }

    .btn8:hover {
        background-color: #2f62a1;
        color: white;
    }


    .searchTips {
        background-color: #ffffff;
        margin-top: 1px;
        padding: 7px 5px 5px 5px;
        font-size: 13px;
        text-align: left;
        cursor: pointer;
        max-width: 230px;
        user-select: none;
    }

    .searchTips:hover {
        background-color: #cae1ff;
    }
</style>