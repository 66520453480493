<template>
    <div style="width:450px;">
        <div v-for="(item, index) in src" :key="index" class="main parent"
            style="position: relative;float: left;margin-right: 10px;"
            :style="{ 'width': width + 'px', 'height': height + 'px' }">
            <Upload v-model="item.src" @change="filesChange" :oss="'cos-tencent'"></Upload>
            <div class="child" v-if="item.src"
                style="cursor: pointer;position: absolute;bottom: 0;left: 0;z-index: 8888;width: 100%;align-items: center;">
                <b-button-group style="width: 100%;">
                    <b-button @click="moveItemL(index)" style="flex: 1;color: white;" variant="primary">
                        <b-icon icon="arrow-left" scale="0.8"></b-icon>
                    </b-button>
                    <b-button @click="deleteItem(index)" style="flex: 1;color: white;" variant="primary">
                        <b-icon icon="trash-fill" aria-hidden="true" scale="0.8"></b-icon>
                    </b-button>
                    <b-button @click="moveItemR(index)" style="flex: 1;color: white;" variant="primary">
                        <b-icon icon="arrow-right" scale="0.8"></b-icon>
                    </b-button>
                </b-button-group>

            </div>

        </div>


        <!-- <div class="main" style="position: relative;float: left;"
            :style="{ 'width': width + 'px', 'height': height + 'px' }">
            <Upload v-model="newimg" @change="filesChange" :oss="'cos-tencent'"></Upload>
        </div> -->
    </div>
</template>
<script>
    import Upload from '../Upload/Upload'
    export default {
        name: 'Uploads',
        components: {
            'Upload': Upload
        },
        model: {
            prop: 'src',
            event: 'change'
        },
        props: {
            src: {
                type: String,
                default: []
            },
            max: {
                type: Number,
                default: 5
            },

            multiple: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            width: {
                type: Number,
                default: 95
            },
            height: {
                type: Number,
                default: 95
            },
            maxSize: {
                type: Number,
                default: 10000000
            },
            type: {
                type: String,
                default: 'url'
            },
            oss: {
                type: String,
                default: 'cos-tencent'
            },
            filesType: {
                type: String,
                default: 'image'
            },
            // imagesList: {
            //     type: Array,
            //     default: function () {
            //         return [];
            //     }
            // }
        },
        watch: {
            src(newValue) {
                this.$emit('src imgs', newValue)
            }
        },
        data() {
            return {
                upload_process: 0,
                uploading: false,
                upload_files_count: 0,
                showPreviewImages: false,
                newimg: null
            }
        },
        mounted() {
            console.log('Uploads mounted2323', this.src, this.src.length, this.max)
            if (!this.src || this.src.length < this.max) {
                console.log('push', )
                for (let index = this.src.length; index < this.max; index++) {
                    this.src.push({
                        src: null
                    })
                }
            }
        },
        methods: {
            filesChange(newValue, oldValue) {
                console.log('文件改变', newValue, oldValue)
                if (!oldValue && this.src.length < this.max) {
                    for (let index = this.src.length; index < this.max; index++) {
                        this.src.push({
                            src: null
                        })
                    }
                }
                this.newimg = null
                // this.imagesList.push(e)
                // console.log('文件改变2', this.imagesList)
            },
            deleteItem(index) {
                this.src.splice(index, 1)
                if (this.src.length < this.max) {
                    for (let index = this.src.length; index < this.max; index++) {
                        this.src.push({
                            src: null
                        })
                    }
                }
            },
            moveItemL(index) {
                if (index > 0) {
                    let take = this.src.splice(index, 1)
                    this.src.splice(index - 1, 0, take[0])
                }
            },
            moveItemR(index) {
                if (index < this.src.length) {
                    let take = this.src.splice(index, 1)
                    this.src.splice(index + 1, 0, take[0])
                }
            }
        }
    }
</script>
<style scoped>
    .main {
        width: 95px;
        height: 95px;
        border-radius: 3px;
        border: 1px solid #d3d3d3;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        cursor: pointer;
    }

    .parent .child {
        display: none;
    }

    .parent:hover .child {
        display: block;
    }
</style>