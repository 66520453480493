<template>
  <div v-if="showModal" class="modal-wrapper" :style="{ opacity: opacity }">
    <div>
      <div class="modal-content" :class="ani" style="width: auto;min-width: 580px;">
        <div v-show="show_loading"
          style="left: 0;top: 0;width:100%;height: 100%;position: absolute;background: #ffffffb8;display: flex;align-items: center;justify-content: center;z-index: 8888;">
          <div class="loading"></div>
        </div>
        <div class="modal-header">
          <span class="modal-title">{{ title }}</span>
          <button @click="hide" type="button" aria-label="Close" class="close">×</button>
        </div>
        <div class="modal-body" style="width:100%">
          <div v-if="type === 'form'">
            <div v-for="(item, index) in data" :key="index" style="display: flex;align-items: center;margin: 10px 0;">
              <span v-if="!item.hideLabel && !item.hide" style="margin-right: 8px;text-align: right;"
                :style="{ 'width': labelWidth }">
                <span v-if="item.required" style="color:red;margin-right: 5px;">*</span>{{ item.label }}
              </span>
              <div v-if="item.type === 'input' && !item.hide">
                <b-input v-if="item.inputType === 'number'" v-model="item.value" :maxlength="item.maxlength || 255"
                  :placeholder="item.placeholder" autocomplete="off" type="number" :disabled="item.disabled"
                  style="width: 250px;font-size: 14px;"></b-input>
                <b-input v-else v-model="item.value" autocomplete="off" :maxlength="item.maxlength || 255"
                  :placeholder="item.placeholder" :disabled="item.disabled" style="width: 250px;font-size: 14px;">
                </b-input>
              </div>
              <div v-else-if="item.type === 'image' && !item.hide">
                <Upload :disabled="item.disabled" v-model="item.value" :oss="item.oss || 'cos-tencent'"></Upload>
              </div>
              <div v-else-if="item.type === 'images' && !item.hide">
                <Uploads v-model="item.value" :oss="item.oss || 'cos-tencent'"></Uploads>
              </div>
              <div v-else-if="item.type === 'video' && !item.hide">
                <Upload :disabled="item.disabled" v-model="item.value" filesType="video"
                  :oss="item.oss || 'cos-tencent'"></Upload>
              </div>
              <div v-else-if="item.type === 'picker' && !item.hide">
                <AddressPicker v-model="item.value"></AddressPicker>
              </div>
              <div v-else-if="item.type === 'select' && !item.hide" style="z-index:9999;">
                <Select v-model="item.value" :showSearch="item.showSearch" :options="item.options" :text="item.text"
                  :showAll="item.showAll" :placeholder="item.placeholder"></Select>
              </div>



              <div v-else-if="item.type === 'textarea' && !item.hide"
                :style="{ width: item.width || '250px', height: item.height || '150px' }">
                <textarea :disabled="item.disabled" v-model="item.value" style="height: 100%;flex: 1;width: 100%;"
                  name="" :placeholder="item.placeholder" id="" cols="30" rows="10"></textarea>
              </div>
              <div v-else-if="item.type === 'checkboxGroup' && !item.hide">
                <div
                  style="min-height: 300px;width: 250px;border-radius: 4px;border: 1px solid #dddddd;overflow-y: scroll;font-weight: 600;font-size: 14px;user-select: none;">
                  <div
                    style="height: 40px;width: 100%;background-color: #dddddd;display: flex;align-items: center;padding:0 12px;box-sizing: border-box;color: #333333;">
                    {{ item.value.title }}</div>
                  <div style="line-height: 30px;width: 100%;text-align: left;padding:0 3px;">
                    <div v-for="(option_item, index) in item.value.option" :key="index"
                      style="display: flex;align-items: center;width: 100%;justify-content: space-between;">
                      <div style="display: flex;align-items: center;">
                        <div style="min-width: 14px;margin-right: 3px;">
                          <b-icon v-show="option_item.item && option_item.item.length > 0"
                            style="transform: rotate(90deg);" icon="triangle-fill" scale="0.6"></b-icon>
                        </div>
                        <div style="color: #333333;">{{ option_item.label }}</div>
                      </div>
                      <div @click="option_item.value = !option_item.value"
                        style="width: 15px;height: 15px;border: 2px solid #333;box-sizing: border-box;margin-right: 5px;display: flex;align-items: center;justify-content: center;">
                        <b-icon v-if="option_item.value" icon="check-lg" variant="#ffffff" scale="0.6"></b-icon>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-else-if="item.type === 'date' && !item.hide">
                <b-form-datepicker id="example-datepicker" v-model="item.value" class="mb-2" placeholder="选择日期"
                  style="font-size: 14px;"></b-form-datepicker>
              </div>


              <span v-if="item.warning" style="color:red;margin-left:10px;font-size: 12px;">* 必填</span>
            </div>
            <slot name="item" style="display: flex;align-items: center;margin: 10px 0;"></slot>
          </div>
          <div v-if="type === 'query'">
            <span style="font-size: 18px;width: 100%;display: flex;">{{ content }}</span>
          </div>
        </div>
        <div v-if="footer" class="modal-footer">
          <div @click="hide" class="btn3">取消</div>
          <div class="mybtn" @click="confirm">确定</div>
        </div>
        <slot name="body" class="modal-body">


        </slot>
        <slot name="footer" class="modal-footer">


        </slot>
      </div>

    </div>

  </div>
</template>

<script>
  import Upload from '../Upload/Upload'
  import Uploads from '../Uploads/Uploads'

  import Select from '../Select/Select'
  import AddressPicker from '../AddressPicker/AddressPicker'

  export default {
    name: 'Modal',
    components: {
      'Upload': Upload,
      'Uploads': Uploads,
      'AddressPicker': AddressPicker,
      Select
    },
    props: {
      src: {
        type: String
      },
      footer: {
        type: Boolean,
        default: true
      },
      labelWidth: {
        type: String,
        default: '135px'
      },
      autoHide: {
        type: Boolean,
        default: true
      },

    },
    data() {
      return {
        ani: 'modal-defalt',
        opacity: 0.5,
        showModal: false,
        data: [],
        content: '',
        title: '',
        type: '',
        show_loading: false
      }
    },
    mounted() {},
    methods: {
      hide() {
        document.body.style.overflow = 'auto';
        this.ani = 'modal-defalt'
        this.opacity = 0
        setTimeout(() => {
          this.showModal = false
        }, 300);
        this.data = []
      },
      show({
        type = '',
        title = '',
        content = '',
        data = []
      }) {
        document.body.style.overflow = 'hidden';
        this.showModal = true
        setTimeout(() => {
          this.ani = 'modal-move'
          this.opacity = 1
        }, 0);
        console.log('模态传递数据', data)
        let newdata = JSON.parse(JSON.stringify(data))
        newdata.forEach(item => {
          item.placeholder = item.placeholder || ''
          item.required = item.required || false
          item.disabled = item.disabled || false
          item.hide = item.hide || false
          item.type = item.type || 'input'
          item.value = item.value || (item.value == 0 ? 0 : (item.type == 'picker' ? [] : ''))
        })
        this.type = type
        this.content = content
        this.data = newdata
        this.title = title

      },
      showLoading() {
        this.show_loading = true
      },
      hideLoading() {
        this.show_loading = false
      },
      confirm() {
        let result = {};
        let resolve = true;
        console.log('ss', this.data)
        this.data.forEach(item => {
          item.warning = false;
          if (item.required === true && ([undefined, null].indexOf(item.value) !== -1 || item.value.length === 0)) {
            item.warning = true;
            resolve = false;
            this.$forceUpdate()
            console.log('存在未填项目', item)
          }
          result[item['name']] = item['value'];
        })

        if (resolve) {
          this.$emit('confirm', result)
          if (this.autoHide) {
            this.hide()
          }

        }
      }
    },
    watch: {

    }
  }
</script>

<style scoped>
  ::-webkit-scrollbar {
    display: none;
  }

  .modal-wrapper {
    height: 100vh;
    width: 100vw;
    display: flex;
    padding: 2rem;
    justify-content: center;
    background: #00000085;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 8888;
    overflow-x: hidden;
    overflow-y: auto;
    transition: opacity 0.15s linear;
    opacity: 0.5;
  }

  .modal-move {
    transform: none;
    transition: transform 0.3s ease-out;
  }

  .modal-defalt {
    transform: translate(0, -50px);
    transition: transform 0.15s ease-in;
  }

  .modal-content {
    /* transition：[属性名] [持续时间] [速度曲线] [延迟时间] */
    height: auto;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    outline: 0;
    overflow: visible;

  }

  .modal-title {
    margin-bottom: 0;
    font-size: 1.25rem;
  }

  .modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    box-sizing: border-box;
  }

  .modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding: 0.75rem;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(0.3rem - 1px);
    border-bottom-left-radius: calc(0.3rem - 1px);
  }

  .modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem;
  }


  .mybtn {
    background-color: #206bc4;
    touch-action: manipulation;
    color: #ffffff;
    cursor: pointer;
    margin: 0.25rem;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .mybtn:hover {
    background-color: #286090;
    color: #ffffff;
  }


  .btn3 {
    background-color: #f0ad4e;
    touch-action: manipulation;
    color: #ffffff;
    cursor: pointer;
    margin: 0.25rem;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .btn3:hover {
    background-color: #e09b3c;
    color: #ffffff;
  }

  .loading {
    border: 8px solid #f3f3f3;
    border-top: 8px solid #3498db;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    animation: spin 1s infinite linear;
  }

  .loading:before {
    content: "";
    display: block;
    position: absolute;
    top: -8px;
    left: -8px;
    width: 64px;
    height: 64px;
    border-top: none;
    border-right: none;
    border-radius: 50%;
    animation: spin 2s infinite linear;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(360deg);
    }
  }
</style>