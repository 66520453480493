<template>
    <div>
        <div class="breadCrumb" style="display: flex;">
            <div @click="tabBreadCrumb(index)" v-for="(item, index) in breadCrumb" :key="index"
                :class="index == bread_crumb_selected ? 'bread_crumb_item_selected' : 'bread_crumb_item'">
                {{ item.name }}
            </div>
        </div>
        <div class="wrapper">
            <div class="body">
                <div v-if="bread_crumb_selected == 0" style="padding: 10px;">
                    <div v-if="evaluationList.length == 0 && !showLoading"
                        style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
                        暂无
                    </div>
                    <Table :fields="fields" :data="evaluationList" :searchValue="searchValue" :loadAll="true"
                        tHeaderStyle="display:none;" tBodyStyle="border-top:none" @scroll="table_scrollEvent">
                        <template v-slot:id="cell">
                            <div
                                style="display: flex;padding: 10px 5px;align-items: flex-start;justify-content: space-between;flex: 1;width: 100%;">
                                <div style="display: flex;width: 150px;">
                                    <img :src="cell.item.avatar" style="width: 30px;height: 30px;border-radius: 50%;" />
                                    <span style="margin-left: 5px;font-size: 13.5px;">
                                        {{ cell.item.nickName }}
                                    </span>
                                </div>
                                <div style="text-align: left;margin-left:70px;flex: 1;font-size: 13px;">
                                    <div style="color:#999">{{ cell.item.evaluationTime }}</div>
                                    <div style="margin-top:6px;font-size: 13.5px;">
                                        <span v-if="cell.item.hidden == 1 "
                                            style="color:red;font-size: 13px;">（该评论已隐藏）</span>
                                        {{ cell.item.evaluation }}</div>
                                    <div v-if="cell.item.id!==replyId && cell.item.replyContent"
                                        style="margin-top:6px;font-size: 13.5px;">
                                        <span style="color:#205788">商家回复：</span> {{ cell.item.replyContent }}</div>
                                    <div style="margin-top:6px;font-size: 13.5px;color: #888;">
                                        <span style="margin-right: 15px;">{{ cell.item.address.city }}</span>
                                        <span style="margin-right: 15px;">{{ cell.item.address.district }}</span>
                                        <span style="margin-right: 15px;">{{ cell.item.goods_name }}</span>
                                    </div>
                                    <div v-if="cell.item.id==replyId"
                                        style="border:1px solid #eee;height: 30px;flex: 1;margin-top: 10px;margin-right: 10px;">
                                        <input v-model="replyContent" type="text" placeholder="回复: 请在此输入回复内容"
                                            style="width:100%"></div>
                                </div>

                            </div>
                            <div
                                style="display: flex;flex-direction: column;justify-content: space-between;height: 100%;font-size: 13.5px;min-width:100px;">
                                <div style="color:#999;">订单号：{{ cell.item.order_id }}</div>
                                <div>
                                    <div class="mybtn"
                                        @click="cell.item.hidden == 0 ?hideEvaluation(cell.item):showEvaluation(cell.item)"
                                        style="margin-top:3px;background: rgba(240, 240, 240);color: #000;border: 1px solid #aaa;box-sizing: border-box;padding: 4px 0;">
                                        {{cell.item.hidden == 0 ?'隐藏评论':'取消隐藏'}}
                                    </div>

                                    <div v-if="replyId !== null">
                                        <div @click="replyId = null,replyContent=null" class="mybtn"
                                            style="margin-top: 10px;">{{cell.item.replyContent ?'取消修改':'取消回复'}}</div>
                                        <div @click="reply(cell.item)" class="mybtn" style="margin-top: 3px;">
                                            {{cell.item.replyContent ?'确认修改':'确认回复'}}
                                        </div>
                                    </div>

                                    <div v-else @click="replyId = cell.item.id" class="mybtn" style="margin-top:3px;">
                                        {{cell.item.replyContent ?'修改回复':'回复评论'}}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Table>
                </div>

                <div v-if="bread_crumb_selected == 1" style="padding: 10px;">
                    <div v-if="complaintList.length == 0 && !showLoading"
                        style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
                        暂无
                    </div>

                    <Table :fields="fields2" :data="complaintList" :searchValue="searchValue" :loadAll="true"
                        tHeaderStyle="max-height:0;opacity: 0;" tBodyStyle="border-top:none"
                        @scroll="table_scrollEvent">
                        <template v-slot:id="cell">
                            <div
                                style="display: flex;padding: 10px 5px;align-items: flex-start;justify-content: space-between;flex: 1;width: 100%;">
                                <div style="display: flex;width: 150px;">
                                    <img :src="cell.item.avatar" style="width: 30px;height: 30px;border-radius: 50%;" />
                                    <span style="margin-left: 5px;font-size: 13.5px;">
                                        {{ cell.item.nickName }}
                                    </span>
                                </div>
                                <div style="text-align: left;margin-left:70px;flex: 1;font-size: 13px;">
                                    <div style="color:#999">{{ cell.item.evaluationTime }}</div>
                                    <div style="margin-top:6px;font-size: 13.5px;">
                                        {{ cell.item.evaluation }}</div>
                                    <div v-if="cell.item.id!==replyId && cell.item.replyContent"
                                        style="margin-top:6px;font-size: 13.5px;">
                                        <span style="color:#205788">商家回复：</span> {{ cell.item.replyContent }}</div>
                                    <div style="margin-top:6px;font-size: 13.5px;color: #888;">
                                        <span style="margin-right: 15px;">{{ cell.item.address.city }}</span>
                                        <span style="margin-right: 15px;">{{ cell.item.address.district }}</span>
                                        <span style="margin-right: 15px;">{{ cell.item.goods_name }}</span>
                                    </div>
                                    <div v-if="cell.item.id==replyId"
                                        style="border:1px solid #eee;height: 30px;flex: 1;margin-top: 10px;margin-right: 10px;">
                                        <input v-model="replyContent" type="text" placeholder="回复: 请在此输入回复内容"
                                            style="width:100%"></div>
                                </div>

                            </div>
                            <div
                                style="display: flex;flex-direction: column;justify-content: space-between;height: 100%;font-size: 13.5px;min-width:100px;">
                                <div style="color:#999;">订单号：{{ cell.item.order_id }}</div>
                                <div>
                                    <div v-if="replyId !== null">
                                        <div @click="replyId = null,replyContent=null" class="mybtn"
                                            style="margin-top: 10px;">{{cell.item.replyContent ?'取消修改':'取消回复'}}</div>
                                        <div @click="reply(cell.item)" class="mybtn" style="margin-top: 3px;">
                                            {{cell.item.replyContent ?'确认修改':'确认回复'}}
                                        </div>
                                    </div>

                                    <div v-else @click="replyId = cell.item.id" class="mybtn" style="margin-top:3px;">
                                        {{cell.item.replyContent ?'修改回复':'回复评论'}}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </Table>


                </div>
                <div v-if="showLoading"
                    style="display: flex;align-items: center;justify-content: center;padding: 20px 0;background-color: #fff;">
                    <img style="margin-right: 5px;" src="../assets/loading.gif" alt="">加载中..
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    const utils = require('../utils/utils')
    import {
        BModal
    } from 'bootstrap-vue'
    import Modal from '../components/Modal/Modal'
    import Table from '../components/Table/Table'
    import Select from '../components/Select/Select'

    export default {
        name: 'Order',
        components: {
            'b-modal': BModal,
            'Modal': Modal,
            'Table': Table,
            'Select': Select,
        },
        data() {
            return {
                tab: [{
                        name: '添加师傅',
                        status: 0,
                        count: 0
                    },
                    {
                        name: '邀请师傅',
                        status: 0,
                        count: 0
                    }
                ],
                breadCrumb: [{
                    name: '用户评价'
                }, {
                    name: '用户投诉'
                }],
                bread_crumb_selected: 0,
                selected: 0,
                showLoading: false,
                preview_images: '',
                showPreviewImages: false,
                remarks: '',
                evaluationList: [],
                complaintList: [],
                dropdown_id: null,
                fields: [{
                    key: 'id',
                    label: '编号',
                    width: '100%'
                }],
                fields2: [{
                    key: 'id',
                    label: '编号',
                    width: '100%'
                }],
                searchValue: null,
                merchantData: {},
                loadAll: false,
                replyId: null,
                replyContent: null
            };
        },
        mounted() {
            // 组件挂载完成回调，处理逻辑
            this.getUserEvaluation()
        },
        methods: {
            tabClick(index) {
                this.selected = index
            },
            tabBreadCrumb(index) {
                this.bread_crumb_selected = index
                if (index == 0) {
                    this.getUserEvaluation()
                } else {
                    this.getUserComplaint()
                }
            },
            getUserEvaluation() {
                this.goodsList = [];
                this.showLoading = true;
                this.get('getUserEvaluation', {}).then((res) => {
                    console.log('res', res)
                    res.evaluationList.forEach(item => {
                        console.log(item.address)
                        if (item.address) {
                            item.address = JSON.parse(item.address)
                        } else {
                            item.address = {}
                        }
                    })
                    this.evaluationList = res.evaluationList
                    this.showLoading = false
                })
            },
            getUserComplaint() {
                this.goodsList = [];
                this.showLoading = true;
                this.get('getUserComplaint', {}).then((res) => {
                    console.log('res', res)
                    res.complaintList.forEach(item => {
                        console.log(item.address)
                        if (item.address) {
                            item.address = JSON.parse(item.address)
                        } else {
                            item.address = {}
                        }
                    })
                    this.complaintList = res.complaintList

                    this.showLoading = false
                })
            },
            reply(item) {
                if (!this.replyContent) {
                    return this.$toast({
                        title: '内容不能为空'
                    })
                }
                this.post('replyEvaluation', {
                    id: item.id,
                    replyContent: this.replyContent,
                }).then((res) => {
                    item.replyContent = this.replyContent
                    this.$toast({
                        title: '回复成功'
                    })
                    this.replyContent = null
                    this.replyId = null
                })
            },
            hideEvaluation(item) {
                this.post('hideEvaluation', {
                    id: item.id,
                }).then((res) => {
                    item.hidden = 1
                    this.$toast({
                        title: '已隐藏该评论'
                    })
                })
            },
            showEvaluation(item) {
                this.post('showEvaluation', {
                    id: item.id,
                }).then((res) => {
                    item.hidden = 0
                    this.$toast({
                        title: '已取消隐藏'
                    })
                })
            }


        }
    }
</script>
<style scoped>
    .flex-btn {
        flex: 1;
        margin-right: 5px;
        justify-content: center;
        display: flex;
    }

    .border-red {
        border: 1px solid #ccc;
    }

    .border-red:hover {
        border: 1px solid #d20a0a;
    }

    .wrapper {
        color: #333;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #dddddd;
    }

    .header {
        background-color: #f5f5f5;
        border-bottom: 1px solid #dddddd;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        padding: 10px 15px;
        font-size: 12.5px;
        font-family: "Microsoft YaHei", SimSun;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #dddddd;
    }

    .bread_crumb_item {
        cursor: pointer;
        border-bottom: 2px solid #fff;
        padding: 10px 15px 8px 15px;
        color: #206bc4;
        font-size: 14px;
        margin-right: 4px;

    }

    .bread_crumb_item_selected {
        cursor: pointer;
        border-bottom: 2px solid #206bc4;
        padding: 10px 15px 8px 15px;
        border-radius: 5px 5px 0 0;
        color: #fff;
        background: #206bc4;
        font-size: 14px;
        margin-right: 4px;

    }

    .bread_crumb_item_selected:hover {
        background: #206bc4;
    }

    .bread_crumb_item:hover {
        background: #eeeeee;
    }

    .body {}

    .tab {
        background-color: white;
        padding: 5px 10px;
        font-size: 11.5px;
        cursor: pointer;
        touch-action: manipulation;
        user-select: none;

    }

    .tab-select {
        color: white;
        background-color: #1f68be;
    }

    .tab:hover {
        background: #e6e6e6;
        color: black;
    }

    .tab:active {
        background: #e6e6e6;
        color: black;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }

    .tab-border-left {
        border-left: 1px solid #cccccc;
    }

    .mybtn {
        background-color: #2c72b0;
        padding: 5px 10px;
        font-size: 11.5px;
        cursor: pointer;
        touch-action: manipulation;
        color: #ffffff;
        user-select: none;
        border-radius: 3px;
    }

    .mybtn:hover {
        background-color: #286090;
        color: #ffffff;
    }

    .mybtn:active {
        background-color: #1d5280;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }


    .btn2 {
        background-color: #e8e8e8;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        padding: 5px 10px;
        font-size: 11.5px;
        cursor: pointer;
        touch-action: manipulation;
        border: 1px solid #555555;
        color: #000000;
        border-radius: 3px;
        user-select: none;
    }

    .btn2:hover {
        background-color: #cccccc;
    }

    .btn2:active {
        background-color: #aaaaaa;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }



    .btn3 {
        background-color: #f0ad4e;
        padding: 8px 17px;
        font-size: 14px;
        cursor: pointer;
        touch-action: manipulation;
        color: #ffffff;
        border-radius: 3px;
    }

    .btn3:hover {
        background-color: #e09b3c;
        color: #ffffff;
    }

    input {
        outline: none;
        height: 30px;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        border: 1px solid #cccccc;
    }

    input:focus {
        border: 1px solid rgb(56, 115, 192) !important;
    }

    .float {
        /* float: left; */
        /* min-width: 25%; */
        height: 100%;
        display: flex;
        padding: 10px 0;
    }


    .btn8 {
        position: absolute;
        right: 50px;
        bottom: 70px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #206bc4;
        z-index: 888;
        color: white;
        padding: 10px 30px;
        border-radius: 7px;
        cursor: pointer;
        font-size: 17px;
    }

    .btn8:hover {
        background-color: #2f62a1;
        color: white;
    }


    .searchTips {
        background-color: #ffffff;
        margin-top: 1px;
        padding: 7px 5px 5px 5px;
        font-size: 13px;
        text-align: left;
        cursor: pointer;
        max-width: 230px;
        user-select: none;
    }

    .searchTips:hover {
        background-color: #cae1ff;
    }
</style>