<template>
  <div>
    <div class="breadCrumb" style="display: flex;">
      <div @click="tabBreadCrumb(index)" v-for="(item, index) in breadCrumb" :key="index"
        :class="index == bread_crumb_selected ? 'bread_crumb_item_selected' : 'bread_crumb_item'">{{ item.name }}
      </div>
    </div>
    <div class="wrapper">
      <div class="header" v-if="bread_crumb_selected !== 3">
        <div v-if="bread_crumb_selected == 0"
          style="display: flex;margin-top: 2px;margin-right: 10px;align-items: center;justify-content: space-between;width: 100%;">
          <div style="display: flex;align-items: center;">
            <div @click="business_cooperating_create" class="mybtn" style="margin-right: 10px;">添加商家</div>
            <div class="mybtn" style="margin-right: 10px;position: relative;">批量导入
              <input ref="fileInput" type="file" @change="importSheet" accept=".xlsx, .xls, .csv"
                style="opacity: 0;position: absolute;left: 0;top: 0;width: 100%;height: 100%;z-index: 555;">
            </div>

          </div>
          <div style="display: flex;align-items: center;">
            <div style="display: flex;align-items: center;margin-top: 2px;margin-right: 5px;">
              <Select @change="citySelectChange" v-model="selected_cities" :options="cities"></Select>
            </div>
            <div style="display: flex;margin-top: 2px;">
              <input v-model="searchValue" type="text" @input="searchInput"
                style="border-radius: 3px 0 0 3px;border-right: none;border: 1px solid #cccccc;width: 130px;"
                placeholder="">
              <div @click="search" class="mybtn" style="border-radius: 0 3px 3px 0;">搜索</div>
            </div>
          </div>
        </div>
        <div v-if="bread_crumb_selected == 1" style="display: flex;margin-top: 2px;margin-right: 10px;">
          <div @click="businesses_info_create" class="mybtn" style="">+ 添加</div>
        </div>
      </div>

      <div class="body">
        <div v-if="bread_crumb_selected == 0" style="padding: 10px;">
          <div v-if="businesses_cooperating_list.length == 0 && !showLoading"
            style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
            暂无
          </div>
          <Table ref="businesses_cooperating" :fields="fields" :data="businesses_cooperating_list"
            :searchValue="searchValue" :loadAll="loadAll" @scroll="table_scrollEvent">
            <template v-slot:operate="cell">
              <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap">
                <div @click="business_cooperating_edit(cell.item)"
                  style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                  <img src="../assets/edit.png" style="width: 22px;height: 22px;display: inline-block;" alt="">
                  <span style="font-size: 13px;">修改</span>
                </div>
                <div @click="business_cooperating_delete(cell.item)"
                  style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                  <img src="../assets/delete2.png" style="width: 22px;height: 22px;display: inline-block;" alt="">
                  <span style="font-size: 13px;">删除</span>
                </div>
              </div>
            </template>
          </Table>
        </div>

        <div v-if="bread_crumb_selected == 1" style="padding: 10px;">
          <div v-if="businesses_info_list.length == 0 && !showLoading"
            style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
            暂无
          </div>
          <div v-for="(item, index) in businesses_info_list" :key="index">
            <div v-show="!item.hide" style="border: 1px solid #337ab7;font-size: 13px;margin-bottom: 10px;">
              <div
                style="display: flex;justify-content: space-between;align-items: center;background: #337ab7;padding: 9px 5px 7px 5px;color: white;border-radius: 1px;">
                <div>申请时间：{{ item.createTimeTxt }}</div>
              </div>
              <b-container class="bv-example-row">
                <b-row style="text-align: left;" cols="1" cols-sm="2" cols-md="4" cols-lg="4">
                  <b-col>
                    <div class="float" style="min-width: 50%;">公司名称：{{ item.business_name }}</div>
                  </b-col>

                  <b-col>
                    <div class="float" style="min-width: 50%;">申请合作地区：{{ item.business_city[1] }}
                    </div>
                  </b-col>

                  <b-col>
                    <div class="float" style="min-width: 50%;">
                      <div style="font-weight: 600;">联系电话：{{ item.phone_number }}</div>
                      <div style="font-size: 12px;margin-left: 5px;cursor: pointer;color: #337ab7;">联系
                      </div>
                    </div>
                  </b-col>

                  <b-col>
                    <div class="float" style="min-width: 50%;">邮箱：{{ item.email }}</div>
                  </b-col>
                  <b-col>
                    <div class="float" style="min-width: 50%;">营业执照地址：{{ item.business_license_address }}</div>
                  </b-col>
                  <b-col>
                    <div class="float" style="min-width: 50%;">营业执照号码：{{ item.business_license_number }}</div>
                  </b-col>

                  <b-col>
                    <div class="float">营业执照照片：<img @click="previewImages(item.business_license_image)"
                        :src="item.juridical_persons_idcard_front" style="width: 88px;height: 88px;cursor: pointer;"
                        alt=""></div>
                  </b-col>
                  <b-col>
                    <div class="float">其他资质：<img @click="previewImages(item.other)" :src="item.id_card_image_front"
                        style="width: 88px;height: 88px;cursor: pointer;" alt=""></div>
                  </b-col>
                  <b-col>
                    <div class="float" style="min-width: 50%;">法人姓名：{{ item.juridical_persons_name }}</div>
                  </b-col>
                  <b-col>
                    <div class="float" style="min-width: 50%;">法人身份证号码：{{ item.juridical_persons_idcard_number }}</div>
                  </b-col>
                  <b-col>
                    <div class="float">法人身份证照片正面：<img @click="previewImages(item.juridical_persons_idcard_front)"
                        :src="item.juridical_persons_idcard_front" style="width: 88px;height: 88px;cursor: pointer;"
                        alt=""></div>
                  </b-col>
                  <b-col>
                    <div class="float">法人身份证照片反面：<img @click="previewImages(item.juridical_persons_idcard_back)"
                        :src="item.juridical_persons_idcard_back" style="width: 88px;height: 88px;cursor: pointer;"
                        alt=""></div>
                  </b-col>

                  <b-col>
                    <div class="float" style="min-width: 50%;">微信账号：{{ item.wechat }}</div>
                  </b-col>

                  <b-col>
                    <div class="float" style="min-width: 50%;">支付宝账号：{{ item.alipay }}</div>
                  </b-col>
                  <b-col>
                    <div class="float" style="min-width: 50%;">微信小程序昵称：{{ item.wechat_app_name }}</div>
                  </b-col>
                  <b-col>
                    <div class="float" style="min-width: 50%;">支付宝小程序昵称：{{ item.ali_app_name }}</div>
                  </b-col>


                </b-row>
              </b-container>
              <div
                style="padding: 10px;text-align: left;display: flex;border-top: 1px solid #eeeeee;font-size: 12px;justify-content: space-between;align-items: center;">
                <div style="display: flex;align-items: center;">
                  <div style="min-width: 60px;">备注信息：</div>
                  <div><span style="color: #666666;margin-right: 4px;">{{ item.remarks }} </span>
                    <span @click="businesses_info_remarks(item)" style="color: #206bc4;cursor: pointer;">{{
                      item.remarks ? '(修改备注)' : '(添加备注)' }}</span>
                  </div>
                </div>
                <div @click="businesses_info_delete(item)" style="color: #fa5151;cursor: pointer;padding-right: 20px;">
                  删除
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="bread_crumb_selected == 2" style="padding: 10px;">
          <div v-if="businesses_application_list.length == 0 && !showLoading"
            style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
            暂无
          </div>
          <div v-for="(item, index) in businesses_application_list" :key="index">
            <div v-show="!item.hide" style="border: 1px solid #337ab7;font-size: 13px;margin-bottom: 10px;">
              <div
                style="display: flex;justify-content: space-between;align-items: center;background: #337ab7;padding: 9px 5px 7px 5px;color: white;border-radius: 1px;">
                <div>申请时间：{{ item.createTimeTxt }}</div>
              </div>
              <b-container class="bv-example-row">
                <b-row style="text-align: left;" cols="1" cols-sm="2" cols-md="4" cols-lg="4">

                  <b-col>
                    <div class="float" style="min-width: 50%;">申请合作地区：{{ item.business_city[1] }}
                    </div>
                  </b-col>
                  <b-col>
                    <div class="float" style="min-width: 50%;">
                      <div style="font-weight: 600;">联系电话：{{ item.phone_number }}</div>
                      <div style="font-size: 12px;margin-left: 5px;cursor: pointer;color: #337ab7;">联系
                      </div>
                    </div>
                  </b-col>
                  <b-col>
                    <div class="float" style="min-width: 50%;">营业执照号码：{{ item.business_license_number }}</div>
                  </b-col>
                  <b-col>
                    <div class="float">营业执照图片：<img @click="previewImages(item.business_license_image)"
                        :src="item.business_license_image" style="width: 88px;height: 88px;cursor: pointer;" alt="">
                    </div>
                  </b-col>
                </b-row>
              </b-container>
              <div
                style="padding: 10px;text-align: left;display: flex;border-top: 1px solid #eeeeee;font-size: 12px;justify-content: space-between;align-items: center;">
                <div style="display: flex;align-items: center;">
                </div>
                <div @click="businesses_application_delete(item)"
                  style="color: #fa5151;cursor: pointer;padding-right: 20px;">
                  删除
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="bread_crumb_selected == 3">
          <!-- 商家地图 -->
          <div style="height: calc(100vh - 130px);background-color: #f1f1f1;">
            <Amap ref="Amap_merchant" @onMarkers="onMarkers" :showTitleBar="false" :showSearchInput="true"
              style="width: 100%;height: calc(100vh - 130px);">
              <template v-slot:content="">
                <div
                  style="position: absolute;top: 0;left: 0;margin: auto;width: 100%;display: flex;align-items: center;font-size: 14.5px;background-color: #00000085;text-align: left;height: 42px;z-index: 5555;padding-left: 10px;z-index: 8888;">
                  <Select @change="mapCitySelectChange" v-model="map_selected_cities" :options="cities"
                    :showAll="false"></Select>
                  <!-- <div style="position: relative;margin-left: 5px;margin-top: 2px;">
                    <input v-model="map_search_keyword" @keyup="map_searchValueChange"
                      style="height: 30px;font-size: 13px; line-height: 30px; background: #ffffff; position: relative;border-radius: 4px;"
                      placeholder="请输入搜索位置" />
                    <div
                      style="position: absolute;top: 30px;left: 0;width: 100%;background-color: white;border-radius: 4px;">
                      <div v-for="(item, index) in searchTips" @click="selcetSearchValue(item)" :key="index"
                        class="searchTips">
                        <div>{{ item.name }}
                          <span style="color: #999999;">{{ item.pname + item.cityname + item.adname }}</span>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
                <div v-if="merchantData.tel"
                  style="position: absolute;top: 42px;left: 0;margin: auto;border-radius: 3px;padding: 15px 10px;width: 250px;display: flex;flex-direction: column;align-items: start;line-height: 30px;font-size: 14.5px;background-color: #ffffff;text-align: left;box-shadow: 0 0 8px 3px #00000055;">
                  <div>商家名称：{{ merchantData.storeName }}</div>
                  <div>联系电话：{{ merchantData.tel }}</div>
                  <div>商家地址：{{ merchantData.province + ' ' + merchantData.city + ' ' + merchantData.detail }}</div>
                </div>
              </template>
            </Amap>
          </div>
        </div>

        <div v-if="showLoading"
          style="display: flex;align-items: center;justify-content: center;padding: 20px 0;background-color: #fff;">
          <img style="margin-right: 5px;" src="../assets/loading.gif" alt="">加载中..
        </div>
      </div>

    </div>
    <!-- 地图选点 -->
    <template>
      <Amap ref="Amap" @click="map_click_getPoint" :showSearchInput="true"
        style="width: 68vw;height: 92vh;position: fixed;left: 0;right: 0;z-index: 52555;top: 0;bottom: 0;margin: auto;">
        <template v-slot:content="">
          <div @click="confirmLocation" class="btn8">
            <img src="../assets/mark.png" style="width: 17px;height: 17px;margin-right: 5px;opacity: 0.9;" alt="">
            确认位置
          </div>
        </template>
      </Amap>
    </template>

    <!-- 图片预览 -->
    <PreviewImages v-model="showPreviewImages" :src="preview_images"></PreviewImages>
    <!-- 模态弹窗 - 商家列表 -->
    <Modal ref="modal_import_sheet" @confirm="modal_import_sheet_submit"></Modal>
    <Modal ref="business_cooperating_create" @confirm="business_cooperating_create_submit">
      <template v-slot:item="">
        <div style="display: flex;align-items: center;margin: 20px 0;">
          <span style="margin-right: 8px;min-width: 135px;text-align: right;">
            <span style="color:red;margin-right: 5px;">*</span>地图经纬度
          </span>
          <b-input v-model="selected_location.lng" style="width: 120px;font-size: 14px;height: 35px;margin-right: 5px;">
          </b-input>
          <b-input v-model="selected_location.lat" style="width: 120px;font-size: 14px;height: 35px"></b-input>
          <button @click="showMap" class="mybtn" style="height: 35px;">打开地图选取位置</button>
        </div>
      </template>
    </Modal>
    <Modal ref="business_cooperating_edit" @confirm="business_cooperating_edit_submit">
      <template v-slot:item="">
        <div style="display: flex;align-items: center;margin: 20px 0;">
          <span style="margin-right: 8px;min-width: 135px;text-align: right;">
            <span style="color:red;margin-right: 5px;">*</span>地图经纬度
          </span>
          <b-input v-model="selected_location.lng" style="width: 120px;font-size: 14px;height: 35px;margin-right: 5px;">
          </b-input>
          <b-input v-model="selected_location.lat" style="width: 120px;font-size: 14px;height: 35px"></b-input>
          <button @click="showMap" class="mybtn" style="height: 35px;">打开地图选取位置</button>
        </div>
      </template>
    </Modal>
    <Modal ref="business_cooperating_delete" @confirm="business_cooperating_delete_submit"></Modal>

    <!--
    <Modal ref="modal_viewPrsonnelDetail" :footer="false"></Modal> -->
    <!-- 模态弹窗 - 信息收录 -->
    <Modal ref="modal_businesses_info_create" @confirm="businesses_info_create_submit"></Modal>
    <Modal ref="modal_businesses_info_delete" @confirm="businesses_info_delete_submit"></Modal>
    <Modal ref="modal_businesses_info_remarks" @confirm="businesses_info_remarks_submit"></Modal>
    <!-- 模态弹窗 - 申请记录 -->
    <Modal ref="modal_businesses_application_delete" @confirm="businesses_application_delete_submit"></Modal>

  </div>
</template>
<script>
  const utils = require('../utils/utils')
  import {
    BModal
  } from 'bootstrap-vue'
  import PreviewImages from '../components/PreviewImages/PreviewImages'
  import Modal from '../components/Modal/Modal'
  import Table from '../components/Table/Table'
  import Select from '../components/Select/Select'
  import Amap from '../components/Amap/Amap'
  import ExcelJS from 'exceljs';

  export default {
    name: 'Order',
    components: {
      'b-modal': BModal,
      'PreviewImages': PreviewImages,
      'Modal': Modal,
      'Table': Table,
      'Select': Select,
      'Amap': Amap
    },
    data() {
      return {
        tab: [{
            name: '添加师傅',
            status: 0,
            count: 0
          },
          {
            name: '邀请师傅',
            status: 0,
            count: 0
          }
        ],
        breadCrumb: [{
            name: '商家列表'
          },
          {
            name: '信息收录'
          },
          {
            name: '申请记录'
          },
          {
            name: '商家位置地图'
          }
        ],
        bread_crumb_selected: 0,
        selected: 0,
        showLoading: false,

        order_id: '',
        preview_images: '',
        showPreviewImages: false,
        remarks: '',
        personnel_cooperating_list: [],
        businesses_info_list: [],
        businesses_application_list: [],
        businesses_cooperating_list: [],
        fields: [{
            key: 'id',
            label: '编号',
            width: '55px'
          },
          {
            key: 'storeName',
            label: '商家名称',
            width: '170px'
          },
          {
            key: 'province',
            label: '省份',
            width: '110px'
          },
          {
            key: 'city',
            label: '城市',
            width: '110px'
          },
          {
            key: 'detail',
            label: '地址明细',
            width: '300px'
          },
          {
            key: 'tel',
            label: '电话',
            width: '130px'
          },
          // { key: 'createTime', label: '创建时间' },
          {
            key: 'operate',
            label: '操作'
          }
        ],
        selected_cities: null,
        searchValue: null,
        map_selected_cities: null,
        cities: [],
        selected_location: {
          lng: '',
          lat: ''
        },
        merchantData: {},
        loadAll: false
      };
    },
    mounted() {
      // 组件挂载完成回调，处理逻辑
      this.business_cooperating_get()

    },
    methods: {

      tabClick(index) {
        this.selected = index
      },
      tabBreadCrumb(index) {
        this.bread_crumb_selected = index
        if (index == 0) {
          this.business_cooperating_get()
        } else if (index == 1) {
          this.businesses_info_get()
        } else if (index == 2) {
          this.businesses_application_get()
        } else if (index == 3) {
          this.merchantData = {}
          setTimeout(() => {
            if (this.bread_crumb_selected == 3) {
              this.showAmap_merchant()
            }
          }, 500);
        }
      },

      /************************ 人员列表 *******************************/
      /**获取合作商家列表*/
      table_scrollEvent() {
        console.log('触发事件')
        this.$refs.businesses_cooperating.showLoading()
        this.business_cooperating_get(true)
      },
      citySelectChange(item) {
        console.log('城市', this.selected_cities)
        this.loadAll = false
        this.business_cooperating_get()
      },
      searchInput(e) {
        if (!this.searchValue) {
          this.business_cooperating_get()
        }
      },
      search() {
        this.business_cooperating_get()
      },
      business_cooperating_get(connect, city = this.selected_cities, key = this.searchValue) {
        if (!connect) {
          this.businesses_cooperating_list = [];
        }
        let bread_crumb_selected = this.bread_crumb_selected;
        console.log('businesses_cooperating_list:', this.businesses_cooperating_list.length)
        let start_id = this.businesses_cooperating_list.length > 0 ? this.businesses_cooperating_list[this
          .businesses_cooperating_list.length - 1].id : 0;
        let count = 50;
        this.post('business_cooperating_get', {
          start_id,
          count,
          city: city,
          key: key
        }).then(({
          list,
          cities
        }) => {
          setTimeout(() => {
            if (bread_crumb_selected !== this.bread_crumb_selected) return false;
            if (!connect) {
              this.businesses_cooperating_list = list
            } else {
              if (list.length > 0) {
                //拼接
                if (this.businesses_cooperating_list[this.businesses_cooperating_list.length - 1].id == list[0]
                  .id) {
                  list.splice(0, 1)
                  this.businesses_cooperating_list = this.businesses_cooperating_list.concat(list)
                }
              } else {
                //已加载全部
                this.loadAll = true
              }
            }
            this.cities = cities
          }, 100);
        })
      },
      business_cooperating_create(e) {
        this.$refs.business_cooperating_create.show({
          type: 'form',
          title: '添加商家',
          data: [{
              name: 'storeName',
              label: '商家名称',
              value: null,
              placeholder: '',
              required: true
            },
            {
              name: 'address',
              label: '所在城市',
              type: 'picker',
              value: null,
              placeholder: '',
              required: true,
              disabled: false
            },
            {
              name: 'detail',
              label: '地址明细',
              value: null,
              placeholder: '',
              required: true
            },
            {
              name: 'tel',
              label: '联系电话',
              value: null,
              placeholder: '',
              required: false
            }
          ]
        })
      },
      business_cooperating_create_submit(data) {
        let {
          lng,
          lat
        } = this.selected_location;
        let province = data.address[0];
        let city = data.address[1];
        this.post('business_cooperating_create', {
          ...data,
          lat,
          lng,
          province,
          city
        }).then(({
          data
        }) => {
          this.$toast({
            title: '新建完成'
          })
          this.business_cooperating_get()
        })
        console.log('确认提交的数据', {
          ...data,
          lat,
          lng,
          province,
          city
        })
      },
      //删除合作商家
      business_cooperating_delete(item) {
        this.$refs.business_cooperating_delete.show({
          type: 'query',
          title: '删除合作商家',
          content: '是否确认删除？',
          data: [{
            name: 'id',
            label: '编码',
            value: item.id,
            required: true,
            disabled: true
          }]
        })
      },
      business_cooperating_delete_submit(item) {
        console.log('删除提交的数据', item)
        this.post('business_cooperating_delete', {
          id: item.id
        }).then(({
          data
        }) => {
          this.$toast({
            title: '删除成功'
          })
          this.business_cooperating_get()
        })
      },
      business_cooperating_edit(item) {
        this.selected_location = {
          lng: item.lng,
          lat: item.lat
        }
        this.$refs.business_cooperating_edit.show({
          type: 'form',
          title: '修改合作商家信息',
          data: [{
              name: 'id',
              label: '编号',
              value: item.id,
              placeholder: '',
              required: true,
              disabled: true
            },
            {
              name: 'storeName',
              label: '商家名称',
              value: item.storeName,
              placeholder: '',
              required: true
            },
            {
              name: 'address',
              label: '所在城市',
              type: 'picker',
              value: item.address,
              placeholder: '',
              required: true,
              disabled: false
            },
            {
              name: 'detail',
              label: '地址明细',
              value: item.detail,
              placeholder: '',
              required: true
            },
            {
              name: 'tel',
              label: '联系电话',
              value: item.tel,
              placeholder: '',
              required: false
            }
          ]
        })
      },
      business_cooperating_edit_submit(data) {
        let {
          lng,
          lat
        } = this.selected_location;
        let province = data.address[0];
        let city = data.address[1];
        console.log('发送的数据', {
          ...data,
          lng,
          lat,
          province,
          city
        })
        this.post('business_cooperating_edit', {
          ...data,
          lng,
          lat,
          province,
          city
        }).then(({
          data
        }) => {
          this.$toast({
            title: '修改成功'
          })
          this.business_cooperating_get()
        })
      },

      importSheet(event) {
        const file = event.target.files[0];
        const workbook = new ExcelJS.Workbook();
        // 读取上传的Excel文件
        let sheetContent = [];
        workbook.xlsx.load(file).then(workbook => {
          const worksheet = workbook.getWorksheet(1);
          worksheet.eachRow((row, rowNumber) => {
            // 处理每一行数据
            // console.log('读取的表格数据：', row.getCell(1).value)
            let storeName = row.getCell(1).value;
            let province = row.getCell(4).value;
            let city = row.getCell(3).value;
            let detail = row.getCell(7).value;
            let tel = row.getCell(5).value + '-' + row.getCell(6).value;
            let lng = row.getCell(8).value;
            let lat = row.getCell(9).value;
            let obj = {
              storeName,
              province,
              city,
              detail,
              tel,
              lng,
              lat
            }
            if (row.getCell(6).value) {
              sheetContent.push(obj)
            }

          })
          this.$refs.modal_import_sheet.show({
            type: 'query',
            title: '批量导入商家',
            content: `已获取${sheetContent.length}条数据，是否批量导入？`,
            data: [{
              name: 'sheet',
              label: '数据',
              value: sheetContent,
              required: true,
              disabled: true
            }]
          })
        });
      },
      modal_import_sheet_submit(data) {
        console.log('批量导入确认', data)
        this.post('business_cooperating_create_batch', {
          list: data.sheet
        }).then(({
          successCount
        }) => {
          this.$toast({
            title: '导入成功数量' + successCount
          })
          this.$refs.fileInput.value = null;
          this.business_cooperating_get()
        })
      },
      map_click_getPoint(res) {
        console.log('click getPoint', res)
        this.selected_location.lng = res.lng;
        this.selected_location.lat = res.lat;
        console.log(this.selected_location)
      },
      confirmLocation() {
        this.$refs.Amap.hide()
      },

      //地图相关
      showMap() {
        this.$refs.Amap.show()
        this.$refs.Amap.onClick()
      },
      mapCitySelectChange() {
        this.showAmap_merchant()
      },
      showAmap_merchant() {
        this.$refs.Amap_merchant.show()
        this.businesses_cooperating_list = [];
        this.showLoading = true;
        let bread_crumb_selected = this.bread_crumb_selected;
        console.log('选择的城市', this.map_selected_cities)
        let city = this.map_selected_cities;
        this.post('get_MerchantAndPersonnel_data', {
          city: city
        }).then(({
          businesses_cooperating,
          cities
        }) => {
          setTimeout(async () => {
            if (bread_crumb_selected !== this.bread_crumb_selected) return false;
            this.businesses_cooperating_list = businesses_cooperating;
            let markers = businesses_cooperating.map(element => {
              return {
                location: [Number(element.lng), Number(element.lat)],
                data: element
              }
            })
            this.$refs.Amap_merchant.show()
            this.$refs.Amap_merchant.searchValueChange({
              target: {
                value: this.map_selected_cities
              }
            })
            this.$refs.Amap_merchant.MarkerCluster(markers)
            if (!cities) {
              this.cities = cities
            }

            this.showLoading = false
          }, 100);
        })
      },
      onMarkers(e) {
        this.merchantData = e.data;
        this.$forceUpdate()
      },

      /**添加服务人员*/
      personnel_cooperating_create(e) {
        this.$refs.modal_personnel_cooperating_create.show({
          type: 'form',
          title: '添加服务人员',
          data: [{
              name: 'business_city',
              label: '合作城市',
              type: 'picker',
              value: null,
              placeholder: '',
              required: true,
              disabled: false
            },
            {
              name: 'name',
              label: '姓名',
              value: null,
              placeholder: '',
              required: true
            },
            {
              name: 'phone_number',
              label: '电话号码',
              value: null,
              placeholder: '',
              required: true,
              maxlength: 11
            },
            {
              name: 'id_card',
              label: '身份证号',
              value: null,
              placeholder: '',
              required: false,
              maxlength: 18
            },
            {
              name: 'id_card_image_front',
              label: '身份证照片正面',
              value: null,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'id_card_image_back',
              label: '身份证照片反面',
              value: null,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'wechat_qr',
              label: '个人微信二维码',
              value: null,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'other',
              label: '其他资质',
              value: null,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'wechat_payment_code',
              label: '微信收款码',
              value: null,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'zfb_payment_code',
              label: '支付宝收款码',
              value: null,
              type: 'image',
              placeholder: '',
              required: false
            }
          ]
        })
      },
      modal_personnel_cooperating_create_submit(data) {
        console.log('发送的数据', data)
        this.post('personnel_cooperating_create', {
          ...data
        }).then(({
          data
        }) => {
          this.$toast({
            title: '新建完成'
          })
          this.business_cooperating_get()
        })
      },
      /**编辑服务人员*/
      personnel_cooperating_edit(item) {
        this.$refs.modal_personnel_cooperating_edit.show({
          type: 'form',
          title: '修改服务人员',
          data: [{
              name: 'id',
              label: '编码',
              value: item.id,
              placeholder: '',
              required: true,
              disabled: true
            },
            {
              name: 'business_city',
              label: '合作城市',
              type: 'picker',
              value: item.business_city,
              placeholder: '',
              required: true,
              disabled: false
            },
            {
              name: 'name',
              label: '姓名',
              value: item.name,
              placeholder: '',
              required: true
            },
            {
              name: 'phone_number',
              label: '电话号码',
              value: item.phone_number,
              placeholder: '',
              required: true,
              maxlength: 11
            },
            {
              name: 'id_card',
              label: '身份证号',
              value: item.id_card,
              placeholder: '',
              required: false,
              maxlength: 18
            },
            {
              name: 'id_card_image_front',
              label: '身份证照片正面',
              value: item.id_card_image_front,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'id_card_image_back',
              label: '身份证照片反面',
              value: item.id_card_image_back,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'wechat_qr',
              label: '个人微信二维码',
              value: item.wechat_qr,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'other',
              label: '其他资质',
              value: item.other,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'wechat_payment_code',
              label: '微信收款码',
              value: item.wechat_payment_code,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'zfb_payment_code',
              label: '支付宝收款码',
              value: item.zfb_payment_code,
              type: 'image',
              placeholder: '',
              required: false
            },
          ]
        })
      },
      modal_personnel_cooperating_edit_submit(data) {
        console.log('发送的数据', data)
        this.post('personnel_cooperating_edit', {
          ...data
        }).then(({
          data
        }) => {
          this.$toast({
            title: '修改成功'
          })
          this.business_cooperating_get()
        })
      },
      //删除服务人员
      personnel_cooperating_delete(item) {
        this.$refs.modal_personnel_cooperating_delete.show({
          type: 'query',
          title: '修改服务人员',
          content: '是否确认删除？',
          data: [{
            name: 'id',
            label: '编码',
            value: item.id,
            required: true,
            disabled: true
          }]
        })
      },
      personnel_cooperating_delete_submit(item) {
        console.log('删除提交的数据', item)
        this.post('personnel_cooperating_delete', {
          id: item.id
        }).then(({
          data
        }) => {
          this.$toast({
            title: '删除成功'
          })
          this.business_cooperating_get()
        })
      },
      /**查看人员身份信息 */
      modal_viewPrsonnelDetail(item) {
        this.$refs.modal_viewPrsonnelDetail.show({
          type: 'form',
          title: '身份信息',
          data: [{
              name: 'name',
              label: '姓名',
              value: item.name,
              placeholder: '',
              disabled: true
            },
            {
              name: 'phone_number',
              label: '电话号码',
              value: item.phone_number,
              placeholder: '',
              maxlength: 11,
              disabled: true
            },
            {
              name: 'id_card',
              label: '身份证号',
              value: item.id_card,
              placeholder: '',
              maxlength: 18,
              disabled: true
            },
            {
              name: 'id_card_image_front',
              label: '身份证照片正面',
              value: item.id_card_image_front,
              type: 'image',
              placeholder: '',
              required: false,
              disabled: true
            },
            {
              name: 'id_card_image_back',
              label: '身份证照片反面',
              value: item.id_card_image_back,
              type: 'image',
              placeholder: '',
              required: false,
              disabled: true
            }
          ]
        })
      },


      /************************ 信息收录 *******************************/
      //获取商家信息列表 
      businesses_info_get() {
        this.businesses_info_list = [];
        this.showLoading = true;
        let bread_crumb_selected = this.bread_crumb_selected;
        this.post('businesses_info_get', {}).then(({
          list
        }) => {
          list.forEach(element => {
            element.business_city = JSON.parse(element.business_city)
            element.createTimeTxt = utils.formatTime(element.createTime, 'year-month-day hour:minute')
          });
          setTimeout(() => {
            if (bread_crumb_selected !== this.bread_crumb_selected) return false;
            this.businesses_info_list = list
            this.showLoading = false
          }, 100);
        })
      },
      //删除商家信息
      businesses_info_delete(item) {
        this.$refs.modal_businesses_info_delete.show({
          type: 'query',
          title: '删除商家信息',
          content: '是否确认删除？',
          data: [{
            name: 'id',
            label: '编码',
            value: item.id,
            required: true,
            disabled: true
          }]
        })
      },
      businesses_info_delete_submit(item) {
        console.log('删除提交的数据', item)
        this.post('businesses_info_delete', {
          id: item.id
        }).then(({
          data
        }) => {
          this.$toast({
            title: '删除成功'
          })
          this.businesses_info_get()
        })
      },
      /**添加商家信息*/
      businesses_info_create(e) {
        this.$refs.modal_businesses_info_create.show({
          type: 'form',
          title: '创建匠人合作申请单',
          data: [{
              name: 'business_city',
              label: '合作城市',
              type: 'picker',
              value: null,
              placeholder: '',
              required: true,
              disabled: false
            },
            {
              name: 'business_name',
              label: '企业名称',
              value: null,
              placeholder: '',
              required: true
            },
            {
              name: 'phone_number',
              label: '电话号码',
              value: null,
              placeholder: '',
              required: true,
              maxlength: 11
            },
            {
              name: 'business_license_number',
              label: '营业执照号码',
              value: null,
              placeholder: '',
              required: false,
              maxlength: 18
            },
            {
              name: 'business_license_address',
              label: '营业执照地址',
              value: null,
              placeholder: '',
              required: false
            },
            {
              name: 'business_license_image',
              label: '营业执照照片',
              value: null,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'juridical_persons_name',
              label: '法人姓名',
              value: null,
              placeholder: '',
              required: false
            },
            {
              name: 'juridical_persons_idcard_number',
              label: '法人身份证号码',
              value: null,
              placeholder: '',
              required: false
            },
            {
              name: 'juridical_persons_idcard_front',
              label: '法人身份证照片(正面)',
              value: null,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'juridical_persons_idcard_back',
              label: '法人身份证照片(反面)',
              value: null,
              type: 'image',
              placeholder: '',
              required: false
            },
            {
              name: 'wechat',
              label: '微信号',
              value: null,
              placeholder: '',
              required: false
            },
            {
              name: 'alipay',
              label: '支付宝账号',
              value: null,
              placeholder: '',
              required: false
            },
            {
              name: 'email',
              label: '邮箱地址',
              value: null,
              placeholder: '',
              required: false
            },
            {
              name: 'wechat_app_name',
              label: '微信小程序昵称',
              value: null,
              placeholder: '',
              required: false
            },
            {
              name: 'ali_app_name',
              label: '支付宝小程序昵称',
              value: null,
              placeholder: '',
              required: false
            },
            {
              name: 'other',
              label: '其他资质',
              value: null,
              type: 'image',
              placeholder: '',
              required: false
            }
          ]
        })
      },
      businesses_info_create_submit(data) {
        console.log('发送的数据', data)
        this.post('businesses_info_create', {
          ...data
        }).then(({
          data
        }) => {
          this.$toast({
            title: '添加成功  '
          })
          this.personnel_info_get()
        })
      },

      businesses_info_remarks(item) {
        this.$refs.modal_businesses_info_remarks.show({
          type: 'form',
          title: '备注',
          data: [{
              name: 'id',
              label: '编码',
              value: item.id,
              required: true,
              disabled: true,
              hide: true
            },
            {
              name: 'remarks',
              label: '备注',
              value: item.remarks,
              placeholder: '',
              required: true,
              type: 'textarea',
              hideLabel: true
            }
          ]
        })
      },
      businesses_info_remarks_submit(item) {
        let id = item.id;
        let remarks = item.remarks;
        this.post('businesses_info_remarks', {
          id,
          remarks
        }).then(res => {
          this.$toast({
            title: '操作成功'
          })
          this.tabBreadCrumb(this.bread_crumb_selected)
        })
      },





      /************************ 申请记录 *******************************/
      /**获取申请记录*/
      businesses_application_get() {
        this.businesses_application_list = [];
        this.showLoading = true;
        let bread_crumb_selected = this.bread_crumb_selected;
        this.post('businesses_application_get', {}).then(({
          list
        }) => {
          list.forEach(element => {
            element.business_city = JSON.parse(element.business_city)
            element.createTimeTxt = utils.formatTime(element.createTime, 'year-month-day hour:minute')
          });
          setTimeout(() => {
            if (bread_crumb_selected !== this.bread_crumb_selected) return false;
            this.businesses_application_list = list
            this.showLoading = false
          }, 100);
        })
      },
      businesses_application_delete(item) {
        console.log(item)
        this.$refs.modal_businesses_application_delete.show({
          type: 'query',
          title: '删除记录',
          content: '是否确认删除？',
          data: [{
            name: 'id',
            label: '编码',
            value: item.id,
            required: true,
            disabled: true
          }]
        })
      },
      businesses_application_delete_submit(item) {
        console.log('删除提交的数据', item)
        this.post('businesses_application_delete', {
          id: item.id
        }).then(({
          data
        }) => {
          this.$toast({
            title: '删除成功'
          })
          this.businesses_application_get()
        })
      },



      // search() {
      //   this.$refs.businesses_cooperating.search({
      //     value: this.searchValue,
      //     fields: ['storeName', 'tel', 'detail', 'city', 'province'],
      //     force: {
      //       fields: 'city',
      //       value: this.selected_cities
      //     }
      //   })
      // },
      previewImages(e) {
        this.showPreviewImages = true
        this.preview_images = [e]
      },


      map_searchValueChange(e) {
        // if(!e.target.value){
        //   this.searchTips = [];
        //   return
        // }
        this.$refs.Amap_merchant.searchValueChange(e).then(res => {
          console.log('map_searchValueChange', res)
          this.searchTips = res;
        })
      },
      selcetSearchValue(item) {
        this.$refs.Amap_merchant.selcetSearchValue(item).then(res => {})
      }

    }
  }
</script>
<style scoped>
  .wrapper {
    color: #333;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #dddddd;
  }

  .header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dddddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
    font-size: 12.5px;
    font-family: "Microsoft YaHei", SimSun;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #dddddd;
  }

  .bread_crumb_item {
    cursor: pointer;
    border-bottom: 2px solid #fff;
    padding: 10px 15px 8px 15px;
    color: #206bc4;
    font-size: 14px;
    margin-right: 4px;

  }

  .bread_crumb_item_selected {
    cursor: pointer;
    border-bottom: 2px solid #206bc4;
    padding: 10px 15px 8px 15px;
    border-radius: 5px 5px 0 0;
    color: #fff;
    background: #206bc4;
    font-size: 14px;
    margin-right: 4px;

  }

  .bread_crumb_item_selected:hover {
    background: #206bc4;
  }

  .bread_crumb_item:hover {
    background: #eeeeee;
  }

  .body {}

  .tab {
    background-color: white;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;

  }

  .tab-select {
    color: white;
    background-color: #1f68be;
  }

  .tab:hover {
    background: #e6e6e6;
    color: black;
  }

  .tab:active {
    background: #e6e6e6;
    color: black;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }

  .tab-border-left {
    border-left: 1px solid #cccccc;
  }

  .mybtn {
    background-color: #2c72b0;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    user-select: none;
    border-radius: 3px;
  }

  .mybtn:hover {
    background-color: #286090;
    color: #ffffff;
  }

  .mybtn:active {
    background-color: #1d5280;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }


  .btn2 {
    background-color: #e8e8e8;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    border: 1px solid #555555;
    color: #000000;
    border-radius: 3px;
    user-select: none;
  }

  .btn2:hover {
    background-color: #cccccc;
  }

  .btn2:active {
    background-color: #aaaaaa;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }



  .btn3 {
    background-color: #f0ad4e;
    padding: 8px 17px;
    font-size: 14px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    border-radius: 3px;
  }

  .btn3:hover {
    background-color: #e09b3c;
    color: #ffffff;
  }

  input {
    outline: none;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }

  input:focus {
    border: 1px solid rgb(56, 115, 192) !important;
  }

  .float {
    /* float: left; */
    /* min-width: 25%; */
    height: 100%;
    display: flex;
    padding: 10px 0;
  }


  .btn8 {
    position: absolute;
    right: 50px;
    bottom: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #206bc4;
    z-index: 888;
    color: white;
    padding: 10px 30px;
    border-radius: 7px;
    cursor: pointer;
    font-size: 17px;
  }

  .btn8:hover {
    background-color: #2f62a1;
    color: white;
  }


  .searchTips {
    background-color: #ffffff;
    margin-top: 1px;
    padding: 7px 5px 5px 5px;
    font-size: 13px;
    text-align: left;
    cursor: pointer;
    max-width: 230px;
    user-select: none;
  }

  .searchTips:hover {
    background-color: #cae1ff;
  }
</style>