<template>
  <div>
    <div class="breadCrumb" style="display: flex;">
      <div @click="tabBreadCrumb(index)" v-for="(item, index) in breadCrumb" :key="index"
        :class="index == bread_crumb_selected ? 'bread_crumb_item_selected' : 'bread_crumb_item'">{{ item.name }}
      </div>
    </div>
    <div v-if="bread_crumb_selected === 0">
      <Goods1></Goods1>
    </div>
    <div v-if="bread_crumb_selected === 1">
      <Goods2></Goods2>
    </div>
  </div>

</template>
<script>
  import {
    BModal
  } from 'bootstrap-vue'
  import Modal from '../components/Modal/Modal'
  import Goods1 from './temp/Goods1'
  import Goods2 from './temp/Goods2'

  export default {
    name: 'Program',
    components: {
      'b-modal': BModal,
      Modal,
      Goods1: Goods1,
      Goods2: Goods2
    },
    data() {
      return {
        bread_crumb_selected: 0,
        breadCrumb: [{
            name: '微信/支付宝项目'
          },
          {
            name: '抖音项目'
          },
        ],

      };
    },
    mounted() {
      this.getGoodsList()
    },
    methods: {
      tabClick(index) {
        this.selected = index
        this.getGoodsList()
      },
      tabBreadCrumb(index) {
        this.bread_crumb_selected = index
        if (index == 0) {
          this.business_cooperating_get()
        } else if (index == 1) {
          this.businesses_info_get()
        } else if (index == 2) {
          this.businesses_application_get()
        } else if (index == 3) {
          this.merchantData = {}
          setTimeout(() => {
            if (this.bread_crumb_selected == 3) {
              this.showAmap_merchant()
            }
          }, 500);
        }
      },

    }
  }
</script>
<style scoped>
  .wrapper {
    color: #333;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #dddddd;
  }

  .header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dddddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
    font-size: 12.5px;
    font-family: "Microsoft YaHei", SimSun;
    display: flex;
    justify-content: space-between;
  }

  .body {
    padding: 15px 15px;
  }

  .tab {
    background-color: white;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
  }

  .tab-select {
    color: white;
    background-color: #1f68be;
  }

  .tab:hover {
    background: #e6e6e6;
    color: black;
  }

  .tab:active {
    background: #e6e6e6;
    color: black;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }

  .tab-border-left {
    border-left: 1px solid #cccccc;
  }

  .mybtn {
    border-radius: 3px;
    background-color: #206bc4;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
  }

  .mybtn:hover {
    background-color: #286090;
    color: #ffffff;
  }


  .btn3 {
    background-color: #f0ad4e;
    padding: 8px 17px;
    font-size: 14px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    border-radius: 3px;
  }

  .btn3:hover {
    background-color: #e09b3c;
    color: #ffffff;
  }



  .btn5 {
    background-color: #d9534f;
    padding: 5px 10px;
    font-size: 12px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
  }

  .btn5:hover {
    background-color: #c9302c;
    color: #ffffff;
  }
</style>
<style>
  .table th,
  .table td {
    padding: 0.25rem !important;
    vertical-align: middle !important;
    border-top: 1px solid #dee2e6 !important;
  }

  .table th {
    height: 40px;
  }

  .bread_crumb_item {
    cursor: pointer;
    border-bottom: 2px solid #fff;
    padding: 10px 15px 8px 15px;
    color: #206bc4;
    font-size: 14px;
    margin-right: 4px;

  }

  .bread_crumb_item_selected {
    cursor: pointer;
    border-bottom: 2px solid #206bc4;
    padding: 10px 15px 8px 15px;
    border-radius: 5px 5px 0 0;
    color: #fff;
    background: #206bc4;
    font-size: 14px;
    margin-right: 4px;

  }

  .bread_crumb_item_selected:hover {
    background: #206bc4;
  }

  .bread_crumb_item:hover {
    background: #eeeeee;
  }
</style>