<template>
    <div>
        <div class="wrapper">
            <div class="header">
                <div v-if="bread_crumb_selected == 0"
                    style="display: flex;margin-top: 2px;margin-right: 10px;align-items: center;justify-content: space-between;width: 100%;">
                    <div style="display: flex;align-items: center;">
                        <div @click="account_create" class="mybtn" style="margin-right: 10px;">创建账号</div>
                        <div @click="create_role" class="mybtn" style="margin-right: 10px;">创建角色</div>
                        <div @click="role_view" class="mybtn"
                            style="margin-right: 10px;background-color: #fff;color: #206bc4;border: 1px solid #206bc4;">
                            角色权限管理</div>
                        <div @click="service_cities_manage" class="mybtn"
                            style="margin-right: 10px;background-color: #fff;color: #39a039;border: 1px solid #39a039;">
                            服务城市管理</div>
                    </div>
                </div>
            </div>

            <div class="body">
                <div v-if="bread_crumb_selected == 0" style="padding: 10px;">
                    <Table ref="adminAccount_list" :fields="fields" :data="adminAccount_list" :searchValue="searchValue"
                        :searchFields="['admin_name', 'admin_phone']">
                        <template v-slot:admin_name="cell">
                            <div style="font-weight: 600;height: 100%;display: flex;align-items: center;">
                                {{ cell.value }}
                            </div>
                        </template>

                        <template v-slot:operate="cell">
                            <div v-if="cell.item.power !== 1"
                                style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap">
                                <div @click="account_edit(cell.item)"
                                    style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                                    <img src="../assets/edit.png"
                                        style="width: 22px;height: 22px;display: inline-block;" alt="">
                                    <span style="font-size: 13px;">修改</span>
                                </div>
                                <div @click="account_delete(cell.item)"
                                    style="color: #aaaaaa;display: flex;flex-direction: column;align-items: center;justify-content: center;cursor: pointer;min-width: 32px;margin-left: 10px;">
                                    <img src="../assets/delete2.png"
                                        style="width: 22px;height: 22px;display: inline-block;" alt="">
                                    <span style="font-size: 13px;">删除</span>
                                </div>
                            </div>
                        </template>
                    </Table>

                    <div v-if="adminAccount_list.length == 0 && !showLoading"
                        style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
                        暂无
                    </div>
                </div>

            </div>

        </div>






        <Modal ref="role_view" @confirm="role_view_submit" :footer="false">
            <template v-slot:body="">
                <div>
                    请选择需要操作的角色
                </div>
                <div style="width: 100%;text-align: left;padding: 10px 30px 20px 30px;   user-select: none;">
                    <div :class="role_selected === item ? 'btn7' : 'btn6'" @click="selectRole(item)"
                        v-for="(item, index) in role_list" :key="index"
                        style="float: left;font-size: 14px;margin-right: 5px;">{{ item.role_name }}</div>
                </div>
            </template>
            <template v-slot:footer="">
                <div
                    style="height: 70px;width: 100%;padding: 10px 30px;border-top: 1px solid #dddddd;display: flex;align-items: center;justify-content: center;   user-select: none;">
                    <div class="btn5" @click="role_delete"
                        style=" font-size: 14px;display: flex;align-items: center;justify-content: center;padding:8px 10px!important;margin-right: 5px;">
                        删除角色
                    </div>
                    <div class="mybtn" @click="role_edit"
                        style=" font-size: 14px;display: flex;align-items: center;justify-content: center;padding:8px 10px!important;">
                        修改角色权限
                    </div>
                </div>
            </template>
        </Modal>

        <Modal ref="service_cities_manage" :footer="false">
            <template v-slot:body="">
                <div style="width: 100%;text-align: left;padding: 0 10px 10px 30px;user-select: none;">
                    <div class="btn6" v-for="(item, index) in service_cities" :key="index"
                        style="float: left;font-size: 14px;position: relative;cursor:auto;margin-right: 5px;user-select: none;margin-top: 4px;">
                        {{ item }}
                        <img src="../assets/close.png" @click="deleteServiceCity(index)" alt=""
                            style="width:15px;height:15px;background-color: #e91a00;border-radius: 50%;position: absolute;right: -5px;top:-5px;padding:3px;cursor: pointer;">
                    </div>
                </div>
                <div
                    style="width: 100%;display: flex;align-items: center;padding: 10px 30px 20px 30px;box-sizing: border-box;">
                    <AddressPicker style="flex: 1;" v-model="service_cities_selected"></AddressPicker>
                    <div class="mybtn" @click="service_city_add"
                        style=" font-size: 14px;display: flex;align-items: center;justify-content: center;padding:8px 10px!important;">
                        添加城市
                    </div>
                </div>

            </template>
            <template v-slot:footer="">
                <div
                    style="height: 70px;width: 100%;padding: 10px 30px;border-top: 1px solid #dddddd;display: flex;align-items: center;justify-content: center;user-select: none;">
                    <!-- <div class="btn5" @click="role_delete"
                        style=" font-size: 14px;display: flex;align-items: center;justify-content: center;padding:8px 10px!important;margin-right: 5px;">
                        删除选中
                    </div> -->
                    <div class="mybtn" @click="service_cities_manage_submit"
                        style=" font-size: 14px;display: flex;align-items: center;justify-content: end;padding:8px 10px!important;">
                        保存设置
                    </div>
                </div>
            </template>
        </Modal>

        <!-- 模态弹窗 - 账号权限 -->
        <Modal ref="account_create" @confirm="account_create_submit">
            <template v-slot:item="">
                <div style="display: flex;align-items: center;margin: 20px 0;">
                    <span style="margin-right: 8px;width: 135px;text-align: right;">
                        <span style="color:red;margin-right: 5px;">*</span>服务城市
                    </span>
                    <div style="">
                        <div :class="create_account_service_cities_selected.indexOf(item) !== -1 ? 'btn7' : 'btn6'"
                            @click="create_account_service_cities_selected_change(item)"
                            v-for="(item, index) in service_cities" :key="index"
                            style="float: left;font-size: 14px;margin-right: 5px;user-select: none;margin-top: 4px;">{{ item
                            }}</div>
                    </div>
                </div>
            </template>
        </Modal>
        <Modal ref="account_edit" @confirm="account_edit_submit">
            <template v-slot:item="">
                <div style="display: flex;margin: 20px 0;">
                    <span style="margin-right: 8px;min-width: 135px;text-align: right;padding-top: 5px;">
                        <span style="color:red;margin-right: 5px;">*</span>服务城市
                    </span>
                    <div style="">
                        <div :class="edit_account_service_cities_selected.indexOf(item) !== -1 ? 'btn7' : 'btn6'"
                            @click="edit_account_service_cities_selected_change(item)"
                            v-for="(item, index) in service_cities" :key="index"
                            style="float: left;font-size: 14px;margin-right: 5px;user-select: none;margin-top: 4px;">{{ item
                            }}</div>
                    </div>
                </div>
            </template>
        </Modal>
        <Modal ref="account_delete" @confirm="account_delete_submit"></Modal>
        <Modal ref="role_create" @confirm="role_create_submit"></Modal>
        <Modal ref="role_delete" @confirm="role_delete_submit"></Modal>
        <Modal ref="role_edit" @confirm="role_edit_submit"></Modal>

        <!-- 图片预览 -->
        <PreviewImages v-model="showPreviewImages" :src="preview_images"></PreviewImages>
    </div>
</template>
<script>
    const utils = require('../utils/utils')
    import PreviewImages from '../components/PreviewImages/PreviewImages'
    import Modal from '../components/Modal/Modal'
    import Table from '../components/Table/Table'
    import Select from '../components/Select/Select'
    import AddressPicker from '../components/AddressPicker/AddressPicker'


    export default {
        name: 'Order',
        components: {
            'PreviewImages': PreviewImages,
            'Modal': Modal,
            'Table': Table,
            'Select': Select,
            'AddressPicker': AddressPicker
        },
        data() {
            return {
                tab: [{
                        name: '添加师傅',
                        status: 0,
                        count: 0
                    },
                    {
                        name: '邀请师傅',
                        status: 0,
                        count: 0
                    }
                ],
                breadCrumb: [{
                        name: '商家列表'
                    },
                    {
                        name: '信息收录'
                    },
                    {
                        name: '申请记录'
                    }
                ],
                bread_crumb_selected: 0,
                selected: 0,
                showLoading: false,
                searchValue: '',
                order_id: '',
                preview_images: '',
                showPreviewImages: false,
                remarks: '',

                selected_cities: null,
                cities: [],


                adminAccount_list: [],
                fields: [{
                        key: 'admin_name',
                        label: '账号名称',
                        width: '130px'
                    },
                    {
                        key: 'admin_account',
                        label: '手机号',
                        width: '150px'
                    },
                    {
                        key: 'role_name',
                        label: '角色',
                        width: '150px'
                    },
                    {
                        key: 'cities',
                        label: '服务城市',
                        width: '203px'
                    },
                    // { key: 'admin_remarks', label: '备注', width: '' },
                    {
                        key: 'createTime',
                        label: '创建时间',
                        width: '203px'
                    },
                    {
                        key: 'operate',
                        label: '操作',
                        width: ''
                    }
                ],
                role_list: [],
                role_selected: {},
                service_cities: [],
                service_cities_selected: [],
                create_account_service_cities_selected: [],
                edit_account_service_cities_selected: []
            };
        },
        mounted() {
            // 组件挂载完成回调，处理逻辑
            this.tabBreadCrumb(0)

        },
        methods: {
            tabClick(index) {
                this.selected = index
            },
            tabBreadCrumb(index) {
                console.log(index)
                this.bread_crumb_selected = index
                if (index == 0) {
                    this.adminAccount_get()
                }
            },
            create_role() {
                this.$refs.role_create.show({
                    type: 'form',
                    title: '创建角色',
                    data: [{
                            name: 'role_name',
                            label: '角色名称',
                            value: null,
                            placeholder: '',
                            required: true
                        },
                        {
                            type: 'checkboxGroup',
                            name: 'permissions',
                            label: '菜单权限',
                            value: {
                                title: '菜单权限',
                                option: [{
                                    label: '订单管理',
                                    value: false,
                                    menu: 'Order'
                                }, {
                                    label: '直选项目',
                                    value: false,
                                    menu: 'Program'
                                }, {
                                    label: '商家管理',
                                    value: false,
                                    menu: 'Merchant'
                                }, {
                                    label: '人员管理',
                                    value: false,
                                    menu: 'Personnel'
                                }, {
                                    label: '通知提醒',
                                    value: false,
                                    menu: 'Notice'
                                }, {
                                    label: '应用管理',
                                    value: false,
                                    menu: 'App'
                                }, {
                                    label: '用户管理',
                                    value: false,
                                    menu: 'User'
                                }, {
                                    label: '评价管理',
                                    value: false,
                                    menu: 'Evaluation'
                                }, {
                                    label: '店铺设置',
                                    value: false,
                                    menu: 'Setting'
                                }]
                            },
                            placeholder: '',
                            required: true
                        },
                    ]
                })
            },
            role_create_submit(data) {
                console.log(data)
                this.post('create_role', {
                    ...data
                }).then(() => {
                    this.$toast({
                        title: '新建完成'
                    })
                    this.tabBreadCrumb(0)
                })
            },
            /************************ 账号权限 *******************************/
            /**获取账号列表*/
            adminAccount_get() {
                this.adminAccount_list = [];
                this.showLoading = true;
                let bread_crumb_selected = this.bread_crumb_selected;
                this.get('adminAccount_get', {}).then(({
                    list,
                    role_list,
                    service_cities
                }) => {
                    if (bread_crumb_selected !== this.bread_crumb_selected) return false;
                    for (let item of list) {
                        for (let role_item of role_list) {
                            if (Number(role_item.id) == item.role_id) {
                                item.role_name = role_item.role_name
                                break
                            }
                        }
                    }
                    this.adminAccount_list = list;
                    this.role_list = role_list;
                    this.service_cities = service_cities;
                    console.log(list, 'role_list:', role_list)
                    this.showLoading = false
                })
            },

            /**获取角色列表*/
            role_get() {
                this.adminAccount_list = [];
                let bread_crumb_selected = this.bread_crumb_selected;
                this.get('role_get', {}).then(({
                    list,
                    cities
                }) => {
                    if (bread_crumb_selected !== this.bread_crumb_selected) return false;
                    this.adminAccount_list = list
                    console.log(list)
                })
            },
            role_view() {
                this.$refs.role_view.show({
                    type: 'form',
                    title: '角色权限管理'
                })
                this.role_selected = {}
            },
            selectRole(item) {
                this.role_selected = item
            },
            role_delete() {
                let item = this.role_selected;
                if (this.role_selected.id) {
                    this.$refs.role_delete.show({
                        type: 'query',
                        title: '删除角色',
                        content: '是否确认删除？',
                        data: [{
                            name: 'id',
                            label: '编码',
                            value: item.id,
                            required: true,
                            disabled: true
                        }]
                    })
                } else {
                    this.$toast({
                        title: '请选择角色'
                    })
                }
            },
            role_delete_submit(data) {
                console.log(data)
                this.post('role_delete', {
                    ...data
                }).then(() => {
                    this.$toast({
                        title: '删除成功'
                    })
                    this.role_selected = {}
                    this.tabBreadCrumb(0)
                })
            },
            role_edit() {
                console.log('edis ', this.role_selected)
                let item = this.role_selected;
                if (item.permissions) {
                    item.permissions = JSON.parse(item.permissions)
                    this.$refs.role_edit.show({
                        type: 'form',
                        title: '创建角色',
                        data: [{
                                name: 'id',
                                label: '编码',
                                value: item.id,
                                required: true,
                                disabled: true
                            },
                            {
                                name: 'role_name',
                                label: '角色名称',
                                value: item.role_name,
                                placeholder: '',
                                required: true
                            },
                            {
                                type: 'checkboxGroup',
                                name: 'permissions',
                                label: '菜单权限',
                                value: {
                                    title: '菜单权限',
                                    option: item.permissions
                                },
                                placeholder: '',
                                required: true
                            },
                        ]
                    })
                } else {
                    this.$toast({
                        title: '请选择角色'
                    })
                }

            },
            role_edit_submit(data) {
                console.log(data)
                this.post('role_edit', {
                    ...data
                }).then(() => {
                    this.$toast({
                        title: '修改成功'
                    })
                    this.$refs.role_edit.hide()
                    this.$refs.role_view.hide()
                    this.tabBreadCrumb(0)
                })
            },
            service_cities_manage() {
                this.$refs.service_cities_manage.show({
                    type: 'form',
                    title: '服务城市管理',
                })
            },
            service_city_add() {
                console.log('选择添加的城市', this.service_cities_selected)
                if (this.service_cities_selected.length !== 0) {
                    if (this.service_cities.indexOf(this.service_cities_selected[1]) == -1) {
                        this.service_cities.push(this.service_cities_selected[1])
                        this.service_cities_selected = []
                    } else {
                        console.log('存在')
                        this.$toast({
                            title: '该城市已存在'
                        })
                    }
                }
            },
            service_cities_manage_submit() {
                let service_cities = this.service_cities
                this.post('service_cities_edit', {
                    service_cities
                }).then(() => {
                    this.$toast({
                        title: '保存成功'
                    })
                    this.$refs.service_cities_manage.hide()
                    this.tabBreadCrumb(0)
                })
            },
            deleteServiceCity(index) {
                this.service_cities.splice(index, 1)
            },
            create_account_service_cities_selected_change(item) {
                console.log('选中的城市', item)
                let index = this.create_account_service_cities_selected.indexOf(item)
                if (index == -1) {
                    this.create_account_service_cities_selected.push(item)
                } else {
                    this.create_account_service_cities_selected.splice(index, 1)
                }
            },
            edit_account_service_cities_selected_change(item) {
                console.log('点击城市修改', item)
                let index = this.edit_account_service_cities_selected.indexOf(item)
                if (index == -1) {
                    this.edit_account_service_cities_selected.push(item)
                } else {
                    this.edit_account_service_cities_selected.splice(index, 1)
                }
            },
            account_create() {
                let options = this.role_list.map(element => {
                    return {
                        text: element.role_name,
                        value: element.id
                    }
                })
                if (options.length > 0) {
                    this.$refs.account_create.show({
                        type: 'form',
                        title: '创建账号',
                        data: [{
                                name: 'admin_name',
                                label: '账号名称',
                                value: null,
                                placeholder: '',
                                required: true
                            },
                            {
                                name: 'admin_phone',
                                label: '登录手机',
                                value: null,
                                placeholder: '',
                                required: true,
                                inputType: 'number'
                            },
                            {
                                name: 'admin_password',
                                label: '账号密码',
                                value: null,
                                placeholder: '',
                                required: true
                            },
                            {
                                type: 'select',
                                name: 'role_id',
                                label: '账号角色',
                                value: options[0].value,
                                options: options,
                                text: options[0].text,
                                placeholder: '',
                                required: true
                            }
                        ]
                    })
                } else {
                    this.$toast({
                        title: '请先创建角色'
                    })
                }

            },
            account_create_submit(data) {
                let create_account_service_cities_selected = this.create_account_service_cities_selected;
                console.log('创建提交的数据u', create_account_service_cities_selected)
                this.post('account_create', {
                    ...data,
                    service_cities: create_account_service_cities_selected
                }).then(() => {
                    this.$toast({
                        title: '创建成功'
                    })
                    this.create_account_service_cities_selected = [];
                    this.tabBreadCrumb(0)
                })
            },
            //删除账号
            account_delete(item) {
                this.$refs.account_delete.show({
                    type: 'query',
                    title: '删除账号',
                    content: '是否确认删除？',
                    data: [{
                        name: 'admin_id',
                        label: '编码',
                        value: item.admin_id,
                        required: true,
                        disabled: true
                    }]
                })
            },
            account_delete_submit(data) {
                this.post('account_delete', {
                    ...data
                }).then(() => {
                    this.$toast({
                        title: '删除成功'
                    })
                    this.tabBreadCrumb(0)
                })
            },


            account_edit(item) {
                let options = this.role_list.map(element => {
                    return {
                        text: element.role_name,
                        value: element.id
                    }
                })
                if (options.length > 0) {
                    if (!item.cities || item.cities == '[]') {
                        this.edit_account_service_cities_selected = []
                    } else {
                        this.edit_account_service_cities_selected = JSON.parse(item.cities);
                    }
                    this.$refs.account_edit.show({
                        type: 'form',
                        title: '编辑账号',
                        data: [{
                                name: 'admin_id',
                                label: '编码',
                                value: item.admin_id,
                                required: true,
                                disabled: true
                            },
                            {
                                name: 'admin_name',
                                label: '账号名称',
                                value: item.admin_name,
                                placeholder: '',
                                required: true
                            },
                            {
                                name: 'admin_phone',
                                label: '登录手机',
                                value: item.admin_account,
                                placeholder: '',
                                required: true,
                                inputType: 'number'
                            },
                            {
                                name: 'admin_password',
                                label: '账号密码',
                                value: item.admin_password,
                                placeholder: '',
                                required: true
                            },
                            {
                                type: 'select',
                                name: 'role_id',
                                label: '账号角色',
                                value: item.role_id,
                                options: options,
                                text: item.role_name,
                                placeholder: '',
                                required: true
                            },
                        ]
                    })

                }
            },
            account_edit_submit(data) {
                let service_cities = this.edit_account_service_cities_selected;
                this.post('account_edit', {
                    ...data,
                    service_cities
                }).then(() => {
                    this.$toast({
                        title: ' 修改成功'
                    })
                    this.tabBreadCrumb(0)
                })
            },












            /**添加服务人员*/
            personnel_cooperating_create(e) {
                this.$refs.modal_personnel_cooperating_create.show({
                    type: 'form',
                    title: '添加服务人员',
                    data: [{
                            name: 'business_city',
                            label: '合作城市',
                            type: 'picker',
                            value: null,
                            placeholder: '',
                            required: true,
                            disabled: false
                        },
                        {
                            name: 'name',
                            label: '姓名',
                            value: null,
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'phone_number',
                            label: '电话号码',
                            value: null,
                            placeholder: '',
                            required: true,
                            maxlength: 11
                        },
                        {
                            name: 'id_card',
                            label: '身份证号',
                            value: null,
                            placeholder: '',
                            required: false,
                            maxlength: 18
                        },
                        {
                            name: 'id_card_image_front',
                            label: '身份证照片正面',
                            value: null,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'id_card_image_back',
                            label: '身份证照片反面',
                            value: null,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'wechat_qr',
                            label: '个人微信二维码',
                            value: null,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'other',
                            label: '其他资质',
                            value: null,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'wechat_payment_code',
                            label: '微信收款码',
                            value: null,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'zfb_payment_code',
                            label: '支付宝收款码',
                            value: null,
                            type: 'image',
                            placeholder: '',
                            required: false
                        }
                    ]
                })
            },
            modal_personnel_cooperating_create_submit({
                data
            }) {
                console.log('发送的数据', data)
                this.post('personnel_cooperating_create', {
                    ...data
                }).then(({
                    data
                }) => {
                    this.$toast({
                        title: '新建完成'
                    })
                    this.business_cooperating_get()
                })
            },
            /**编辑服务人员*/
            personnel_cooperating_edit(item) {
                this.$refs.modal_personnel_cooperating_edit.show({
                    type: 'form',
                    title: '修改服务人员',
                    data: [{
                            name: 'id',
                            label: '编码',
                            value: item.id,
                            placeholder: '',
                            required: true,
                            disabled: true
                        },
                        {
                            name: 'business_city',
                            label: '合作城市',
                            type: 'picker',
                            value: item.business_city,
                            placeholder: '',
                            required: true,
                            disabled: false
                        },
                        {
                            name: 'name',
                            label: '姓名',
                            value: item.name,
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'phone_number',
                            label: '电话号码',
                            value: item.phone_number,
                            placeholder: '',
                            required: true,
                            maxlength: 11
                        },
                        {
                            name: 'id_card',
                            label: '身份证号',
                            value: item.id_card,
                            placeholder: '',
                            required: false,
                            maxlength: 18
                        },
                        {
                            name: 'id_card_image_front',
                            label: '身份证照片正面',
                            value: item.id_card_image_front,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'id_card_image_back',
                            label: '身份证照片反面',
                            value: item.id_card_image_back,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'wechat_qr',
                            label: '个人微信二维码',
                            value: item.wechat_qr,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'other',
                            label: '其他资质',
                            value: item.other,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'wechat_payment_code',
                            label: '微信收款码',
                            value: item.wechat_payment_code,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'zfb_payment_code',
                            label: '支付宝收款码',
                            value: item.zfb_payment_code,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                    ]
                })
            },
            modal_personnel_cooperating_edit_submit(data) {
                console.log('发送的数据', data)
                this.post('personnel_cooperating_edit', {
                    ...data
                }).then(({
                    data
                }) => {
                    this.$toast({
                        title: '修改成功'
                    })
                    this.business_cooperating_get()
                })
            },
            //删除服务人员
            personnel_cooperating_delete(item) {
                this.$refs.modal_personnel_cooperating_delete.show({
                    type: 'query',
                    title: '修改服务人员',
                    content: '是否确认删除？',
                    data: [{
                        name: 'id',
                        label: '编码',
                        value: item.id,
                        required: true,
                        disabled: true
                    }]
                })
            },
            personnel_cooperating_delete_submit(item) {
                console.log('删除提交的数据', item)
                this.post('personnel_cooperating_delete', {
                    id: item.id
                }).then(({
                    data
                }) => {
                    this.$toast({
                        title: '删除成功'
                    })
                    this.business_cooperating_get()
                })
            },
            /**查看人员身份信息 */
            modal_viewPrsonnelDetail(item) {
                this.$refs.modal_viewPrsonnelDetail.show({
                    type: 'form',
                    title: '身份信息',
                    data: [{
                            name: 'name',
                            label: '姓名',
                            value: item.name,
                            placeholder: '',
                            disabled: true
                        },
                        {
                            name: 'phone_number',
                            label: '电话号码',
                            value: item.phone_number,
                            placeholder: '',
                            maxlength: 11,
                            disabled: true
                        },
                        {
                            name: 'id_card',
                            label: '身份证号',
                            value: item.id_card,
                            placeholder: '',
                            maxlength: 18,
                            disabled: true
                        },
                        {
                            name: 'id_card_image_front',
                            label: '身份证照片正面',
                            value: item.id_card_image_front,
                            type: 'image',
                            placeholder: '',
                            required: false,
                            disabled: true
                        },
                        {
                            name: 'id_card_image_back',
                            label: '身份证照片反面',
                            value: item.id_card_image_back,
                            type: 'image',
                            placeholder: '',
                            required: false,
                            disabled: true
                        }
                    ]
                })
            },


            /************************ 信息收录 *******************************/
            //获取商家信息列表 
            businesses_info_get() {
                this.businesses_info_list = [];
                this.showLoading = true;
                let bread_crumb_selected = this.bread_crumb_selected;
                this.post('businesses_info_get', {}).then(({
                    list
                }) => {
                    list.forEach(element => {
                        element.business_city = JSON.parse(element.business_city)
                        element.createTimeTxt = utils.formatTime(element.createTime,
                            'year-month-day hour:minute')
                    });
                    setTimeout(() => {
                        if (bread_crumb_selected !== this.bread_crumb_selected) return false;
                        this.businesses_info_list = list
                        this.showLoading = false
                    }, 100);
                })
            },
            //删除商家信息
            businesses_info_delete(item) {
                this.$refs.modal_businesses_info_delete.show({
                    type: 'query',
                    title: '删除商家信息',
                    content: '是否确认删除？',
                    data: [{
                        name: 'id',
                        label: '编码',
                        value: item.id,
                        required: true,
                        disabled: true
                    }]
                })
            },
            businesses_info_delete_submit(item) {
                console.log('删除提交的数据', item)
                this.post('businesses_info_delete', {
                    id: item.id
                }).then(({
                    data
                }) => {
                    this.$toast({
                        title: '删除成功'
                    })
                    this.businesses_info_get()
                })
            },
            /**添加商家信息*/
            businesses_info_create(e) {
                this.$refs.modal_businesses_info_create.show({
                    type: 'form',
                    title: '创建匠人合作申请单',
                    data: [{
                            name: 'business_city',
                            label: '合作城市',
                            type: 'picker',
                            value: null,
                            placeholder: '',
                            required: true,
                            disabled: false
                        },
                        {
                            name: 'business_name',
                            label: '企业名称',
                            value: null,
                            placeholder: '',
                            required: true
                        },
                        {
                            name: 'phone_number',
                            label: '电话号码',
                            value: null,
                            placeholder: '',
                            required: true,
                            maxlength: 11
                        },
                        {
                            name: 'business_license_number',
                            label: '营业执照号码',
                            value: null,
                            placeholder: '',
                            required: false,
                            maxlength: 18
                        },
                        {
                            name: 'business_license_address',
                            label: '营业执照地址',
                            value: null,
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'business_license_image',
                            label: '营业执照照片',
                            value: null,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'juridical_persons_name',
                            label: '法人姓名',
                            value: null,
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'juridical_persons_idcard_number',
                            label: '法人身份证号码',
                            value: null,
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'juridical_persons_idcard_front',
                            label: '法人身份证照片(正面)',
                            value: null,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'juridical_persons_idcard_back',
                            label: '法人身份证照片(反面)',
                            value: null,
                            type: 'image',
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'wechat',
                            label: '微信号',
                            value: null,
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'alipay',
                            label: '支付宝账号',
                            value: null,
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'email',
                            label: '邮箱地址',
                            value: null,
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'wechat_app_name',
                            label: '微信小程序昵称',
                            value: null,
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'ali_app_name',
                            label: '支付宝小程序昵称',
                            value: null,
                            placeholder: '',
                            required: false
                        },
                        {
                            name: 'other',
                            label: '其他资质',
                            value: null,
                            type: 'image',
                            placeholder: '',
                            required: false
                        }
                    ]
                })
            },
            businesses_info_create_submit(data) {
                console.log('发送的数据', data)
                this.post('businesses_info_create', {
                    ...data
                }).then(({
                    data
                }) => {
                    this.$toast({
                        title: '添加成功  '
                    })
                    this.personnel_info_get()
                })
            },

            businesses_info_remarks(item) {
                this.$refs.modal_businesses_info_remarks.show({
                    type: 'form',
                    title: '备注',
                    data: [{
                            name: 'id',
                            label: '编码',
                            value: item.id,
                            required: true,
                            disabled: true,
                            hide: true
                        },
                        {
                            name: 'remarks',
                            width: '100%',
                            label: '备注',
                            value: item.remarks,
                            placeholder: '',
                            required: true,
                            type: 'textarea',
                            hideLabel: true
                        }
                    ]
                })
            },
            businesses_info_remarks_submit(item) {
                let id = item.id;
                let remarks = item.remarks;
                this.post('businesses_info_remarks', {
                    id,
                    remarks
                }).then(res => {
                    this.$toast({
                        title: '操作成功'
                    })
                    this.tabBreadCrumb(this.bread_crumb_selected)
                })
            },





            /************************ 申请记录 *******************************/
            /**获取申请记录*/
            businesses_application_get() {
                this.businesses_application_list = [];
                this.showLoading = true;
                let bread_crumb_selected = this.bread_crumb_selected;
                this.post('businesses_application_get', {}).then(({
                    list
                }) => {
                    list.forEach(element => {
                        element.business_city = JSON.parse(element.business_city)
                        element.createTimeTxt = utils.formatTime(element.createTime,
                            'year-month-day hour:minute')
                    });
                    setTimeout(() => {
                        if (bread_crumb_selected !== this.bread_crumb_selected) return false;
                        this.businesses_application_list = list
                        this.showLoading = false
                    }, 100);
                })
            },
            businesses_application_delete(item) {
                console.log(item)
                this.$refs.modal_businesses_application_delete.show({
                    type: 'query',
                    title: '删除记录',
                    content: '是否确认删除？',
                    data: [{
                        name: 'id',
                        label: '编码',
                        value: item.id,
                        required: true,
                        disabled: true
                    }]
                })
            },
            businesses_application_delete_submit(item) {
                console.log('删除提交的数据', item)
                this.post('businesses_application_delete', {
                    id: item.id
                }).then(({
                    data
                }) => {
                    this.$toast({
                        title: '删除成功'
                    })
                    this.businesses_application_get()
                })
            },
            citySelectChange(item) {
                this.search()
            },
            search() {
                this.$refs.personnel_cooperating.search({
                    value: this.searchValue,
                    fields: ['name', 'phone_number'],
                    force: {
                        fields: 'business_city',
                        value: this.selected_cities
                    }
                })
            },
            searchInput(e) {
                this.search()
                // if(e.target.value.length===0){
                //     console.log('ssadasd')
                //     this.$refs.personnel_cooperating.reset()
                // }
            },

            addServiceCity() {
                this.service_cities.push({})
                console.log('service_cities', this.service_cities)
            },


            previewImages(e) {
                this.showPreviewImages = true
                this.preview_images = [e]
            },

        }
    }
</script>
<style scoped>
    .wrapper {
        color: #333;
        background-color: #fff;
        border-radius: 3px;
        border: 1px solid #dddddd;
    }

    .header {
        background-color: #f5f5f5;
        border-bottom: 1px solid #dddddd;
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
        padding: 10px 15px;
        font-size: 12.5px;
        font-family: "Microsoft YaHei", SimSun;
        display: flex;
        justify-content: space-between;
        border-top: 1px solid #dddddd;
    }

    .bread_crumb_item {
        cursor: pointer;
        border-bottom: 2px solid #fff;
        padding: 10px 15px 8px 15px;
        color: #206bc4;
        font-size: 14px;
        margin-right: 4px;

    }

    .bread_crumb_item_selected {
        cursor: pointer;
        border-bottom: 2px solid #206bc4;
        padding: 10px 15px 8px 15px;
        border-radius: 5px 5px 0 0;
        color: #fff;
        background: #206bc4;
        font-size: 14px;
        margin-right: 4px;

    }

    .bread_crumb_item_selected:hover {
        background: #206bc4;
    }

    .bread_crumb_item:hover {
        background: #eeeeee;
    }

    .body {}

    .tab {
        background-color: white;
        padding: 5px 10px;
        font-size: 11.5px;
        cursor: pointer;
        touch-action: manipulation;
        user-select: none;

    }

    .tab-select {
        color: white;
        background-color: #1f68be;
    }

    .tab:hover {
        background: #e6e6e6;
        color: black;
    }

    .tab:active {
        background: #e6e6e6;
        color: black;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }

    .tab-border-left {
        border-left: 1px solid #cccccc;
    }

    .mybtn {
        background-color: #2c72b0;
        padding: 5px 10px;
        font-size: 11.5px;
        cursor: pointer;
        touch-action: manipulation;
        color: #ffffff;
        user-select: none;
        border-radius: 3px;
    }

    .mybtn:hover {
        background-color: #286090;
        color: #ffffff;
    }

    .mybtn:active {
        background-color: #1d5280;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }


    .btn2 {
        background-color: #e8e8e8;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
        padding: 5px 10px;
        font-size: 11.5px;
        cursor: pointer;
        touch-action: manipulation;
        border: 1px solid #555555;
        color: #000000;
        border-radius: 3px;
        user-select: none;
    }

    .btn2:hover {
        background-color: #cccccc;
    }

    .btn2:active {
        background-color: #aaaaaa;
        box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    }



    .btn3 {
        background-color: #f0ad4e;
        padding: 8px 17px;
        font-size: 14px;
        cursor: pointer;
        touch-action: manipulation;
        color: #ffffff;
        border-radius: 3px;
    }

    .btn3:hover {
        background-color: #e09b3c;
        color: #ffffff;
    }

    input {
        outline: none;
        height: 30px;
        padding: 5px 10px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 3px;
        border: 1px solid #cccccc;
    }

    input:focus {
        border: 1px solid #3873c0 !important;
    }

    .float {
        /* float: left; */
        /* min-width: 25%; */
        height: 100%;
        display: flex;
        padding: 10px 0;
    }


    .btn5 {
        background-color: #d9534f;
        padding: 5px 10px;
        font-size: 12px;
        cursor: pointer;
        touch-action: manipulation;
        color: #ffffff;
        border-radius: 3px;

    }

    .btn5:hover {
        background-color: #c9302c;
        color: #ffffff;
    }




    .btn6 {
        border: 1px solid #cccccc;
        padding: 5px 10px;
        font-size: 12px;
        cursor: pointer;
        touch-action: manipulation;
        color: #000;
        border-radius: 3px;
    }


    .btn6:hover {
        background-color: #eeeeee;
        color: #000;

    }

    .btn7 {
        border: 1px solid #2c72b0;
        padding: 5px 10px;
        font-size: 12px;
        cursor: pointer;
        touch-action: manipulation;
        color: #fff;
        border-radius: 3px;
        background-color: #2c72b0;
    }
</style>