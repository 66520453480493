<template>
    <div class="main" style="position: relative;" :style="{ 'width': width + 'px', 'height': height + 'px' }">
        <div v-if="!disabled">
            <input type="file" name="file" :multiple="multiple" @change="fileChange($event)"
                style="background-color: darkgreen;height: 100%;width:  100%; opacity: 0;position: absolute; left: 0;top: 0;z-index: 555; cursor: pointer; background: #000000;">
            <b-progress v-if="uploading"
                style="width:80%;position: absolute;bottom: 20px;z-index: 556;left: 0;right: 0;margin: auto;" :max="1">
                <b-progress-bar :value="upload_process">
                    <span><strong>{{ upload_process }} / {{ upload_files_count }}</strong></span>
                </b-progress-bar>
            </b-progress>
            <div v-if="src && src.length > 0" style="position: relative;"
                :style="{ 'width': width + 'px', 'height': height + 'px' }">
                <img v-if="filesType == 'image'" :src="src" style="width:  100%;height:  100%;background-size:contain">
                <video v-else-if="filesType == 'video'" :src="src"
                    style="width:  100%;height:  100%;background-size:contain"></video>
                <div
                    style="bottom: 0;right: 0;position: absolute;background-color: #00000085;display: flex;align-items: center;justify-content: center;width:  100%;font-size: 12px;padding: 3px 0;font-weight: 600;color: #ffffffba;height: 25px;">
                    <span>{{ uploading ? '正在上传' : '重新上传' }}</span>
                </div>
            </div>
            <div v-else
                style="display: flex;align-items: center;justify-content: center;flex-direction: column;position: relative;">
                <img src="../../assets/upload.png" style="width: 34px;height: 34px;">
                <div style="font-size: 12px;color: #888888;height: 20px;">{{ filesType == 'image' ? '上传图片' : '上传视频' }}
                </div>
            </div>
        </div>
        <div v-else>
            <div @click="showPreviewImages = true" style="position: relative;width:  100%;height:  100%;">
                <img :src="src" style="width:  100%;height: 100%;background-size:contain">
            </div>
            <PreviewImages v-model="showPreviewImages" :src="[src]"></PreviewImages>
        </div>
    </div>
</template>
<script>
    import PreviewImages from '../PreviewImages/PreviewImages'
    export default {
        name: 'Upload',
        components: {
            PreviewImages
        },
        model: {
            prop: 'src',
            event: 'change'
        },
        props: {
            src: {
                type: String,
                default: ''
            },
            multiple: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            width: {
                type: Number,
                default: 95
            },
            height: {
                type: Number,
                default: 95
            },
            maxSize: {
                type: Number,
                default: 10000000
            },
            type: {
                type: String,
                default: 'url'
            },
            oss: {
                type: String,
                default: 'cos-tencent'
            },
            filesType: {
                type: String,
                default: 'image'
            }
        },
        watch: {
            src(newValue, oldValue) {
                this.$emit('change', newValue, oldValue)
            }
        },
        data() {
            return {
                upload_process: 0,
                uploading: false,
                upload_files_count: 0,
                showPreviewImages: false,
            }
        },
        mounted() {
            console.log('Image mounted')
        },
        methods: {
            fileChange(e) {
                let that = this;
                console.log('开始上传文件', that.$cos, this.oss)
                switch (this.oss) {
                    case 'cos-tencent':
                        if (that.$cos.cos && that.$cos.Bucket && that.$cos.Region) {
                            that.upload_process = 0;
                            //整理文件列表
                            var files = [];
                            for (let i = 0; i < e.target.files.length; i++) {
                                if (e.target.files[i].size < that.maxSize) {
                                    files.push({
                                        Bucket: that.$cos.Bucket,
                                        /* 填写自己的bucket，必须字段 */
                                        Region: that.$cos.Region,
                                        /* 存储桶所在地域，必须字段 */
                                        Key: e.target.files[i]
                                            .name, // (Date.now() + i),  /* 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段 */
                                        Body: e.target.files[i],
                                        /* 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象 */
                                        Prefix: '/',
                                        onTaskReady: function (taskId) {
                                            /* taskId可通过队列操作来取消上传cos.cancelTask(taskId)、停止上传cos.pauseTask(taskId)、重新开始上传cos.restartTask(taskId) */
                                            // console.log(taskId);
                                        }
                                    })
                                } else {
                                    that.$emit('error', {
                                        errMsg: '选择的图片大小超过限制'
                                    })
                                }
                            }

                            var files_count = files.length; /* 文件数量 */
                            that.upload_files_count = files_count;
                            console.log(`待上传的文件数量${files_count} 文件列表：`, files)
                            if (files_count == 0) return;
                            var success = 0; /* 上传成功数量 */
                            that.uploading = true; /* 显示上传进度 */
                            //开始上传
                            that.$cos.cos.uploadFiles({
                                files: files,
                                SliceSize: 1024 * 1024 * 10,
                                /* 设置大于10MB采用分块上传 */
                                onFileFinish: function (err, data, options) {
                                    /* 上传完成触发 */
                                    files_count -= 1 /* 每进行结束一次上传，待上传文件数-1 */
                                    success += err ? 0 : 1
                                    that.upload_process++
                                    that.src = `https://${data.Location}`;
                                    console.log('update load data', data, options.Key)
                                    if (files_count == 0) {
                                        /* 待上传文件数为0 即所有文件上传结束*/
                                        if (success == files.length) {
                                            /* 判断所有文件是否上传成功 */
                                            console.log(`上传成功 ${success}张`)
                                            setTimeout(() => {
                                                that.uploading = false
                                            }, 500);

                                        } else {
                                            console.log(`上传成功 ${success}张 失败${files.length - success}张`)
                                        }
                                    }
                                    console.log(options.Key + '上传' + (err ? '失败' : '完成'), '剩余',
                                        files_count);
                                },
                            }, (err, data) => {
                                console.log(err || data);
                                if (err) {
                                    console.error('云存储配置错误！', err)
                                }
                            });
                        } else {
                            console.error('云存储配置错误')
                            that.$toast({
                                title: '云存储配置错误'
                            })
                        }
                        break
                    default:
                        console.log('sss')
                        if (e.target.files.length > 0) {
                            let file = e.target.files[0]; //目前只需上传一张图片,无多文件上传需要
                            const formData = new FormData();
                            formData.append('image', file); // 添加上传的文件
                            console.log('上传的文件', file)
                            that.post('upload', {
                                formData
                            }, {
                                'Content-Type': 'multipart/form-data' // 设置请求头为FormData类型
                            }).then(res => {
                                console.log('上传文件到服务器返回')
                            })
                        }

                }



            },
        }
    }
</script>
<style scoped>
    .main {
        width: 95px;
        height: 95px;
        border-radius: 3px;
        border: 1px solid #d3d3d3;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        overflow: hidden;
        cursor: pointer;
    }

    input {}
</style>