<template>
  <div class="wrapper">
    <div class="header">
      <div style="display: flex;margin-top: 2px;border-radius:3px;overflow: hidden;border: 1px solid #cccccc;">
        <div @click="tabClick(index)" v-for="(item, index) in tab" :key="index"
          :class="{ 'tab': true, 'tab-select': index === selected, 'tab-border-left': index != 0 }">
          {{ item.name }}<span :style="item.status == 0 ? 'color:#d90404' : item.status == 1 ? 'color:#2ba245' : ''">({{
            item.count }})</span></div>
      </div>
      <div style="display: flex;">
        <div style="display: flex;align-items: center;">
          <div style="display: flex;align-items: center;margin-top: 2px;margin-right: 5px;">
            <Select @change="citySelectChange" v-model="selected_cities" :options="cities"></Select>
          </div>
          <div style="display: flex;margin-top: 2px;">
            <input v-model="searchValue" type="text" @input="searchInput"
              style="border-radius: 3px 0 0 3px;border-right: none;border: 1px solid #cccccc;width: 130px;"
              placeholder="">
            <div @click="search" class="mybtn" style="border-radius: 0 3px 3px 0;">搜索</div>
          </div>
        </div>
      </div>

    </div>
    <div class="body">
      <div v-if="orderList">
        <Table ref="orderList" :fields="fields" :data="orderList" :searchValue="searchValue" :loadAll="loadAll"
          @scroll="table_scrollEvent" :tHeaderItemBorder="false" :tBodyItemBorder="false"
          :tHeaderStyle="{ 'padding': '0 10px', 'font-size': '13px' }"
          :tBodyStyle="{ 'padding': '10px', 'font-size': '13px' }"
          :RowStyle="{ 'border-top': 'none', 'box-shadow': '0 0 0 1px #337ab7', 'margin-bottom': '10px', 'border-radius': '1px' }">
          <template v-slot:header="{ item }">
            <div
              style="display: flex;justify-content: space-between;padding: 9px 5px 7px 5px;color: white;border-radius: 1px 1px 0 0 ;font-size: 13px;"
              :style="{background:item.client=='dy'?'#333333':'#337ab7'}">
              <div>下单时间：{{ item.createTimeTxt }}</div>
              <div style="white-space: pre-wrap;">
                {{ (item.client == 'wx' ? '（微信订单）' : item.client == 'zfb' ?'（支付宝订单）':'（抖音订单）') + item.name }}<span
                  style="margin-left: 4px;">{{ '丨 订单号：#' + item.order_id }}</span></div>
            </div>
          </template>
          <template v-slot:fullName="{ item }">
            <div style="height: 100%;width: 100%;text-align: left;font-size: 13px;">{{ item.fullName }}
            </div>
          </template>
          <template v-slot:phone="{ item }">
            <div style="height: 100%;display: flex;font-size: 13px;">
              <div>{{ item.phone }} </div>

            </div>
          </template>
          <template v-slot:phoneNumber="{ item }">
            <div style="height: 100%;display: flex;font-size: 13px;">
              <div>{{ item.phoneNumber }} </div>
            </div>
          </template>
          <template v-slot:address="{ item }">
            <div style="display: flex;font-size: 13px;">
              <img src="../assets/local.png"
                style="height: 15px;margin-right:2px;cursor: pointer;opacity: 0.9;margin-top: 2px;"
                @click="showMap(item)">
              服务地址：{{
                item.address.province + ' ' +
                item.address.city + ' ' + item.address.district + ' ' + item.address.street_number + '(' +
                item.houseNumber + ')' }}
            </div>
          </template>
          <template v-slot:createTimeTxt="{ item }">
            <div style="height: 100%;display: flex;font-size: 13px;">{{ item.createTimeTxt }}
            </div>
          </template>
          <template v-slot:status="{ item }">
            <div :id="'popover-target_' + item.order_id"
              style="height: 100%;display: flex;font-size: 12px;color: #206bc4;cursor: pointer;user-select: none;">{{
                ['待商家接单', '进行中',
                  '已完成', '已取消'][item.status] }}
            </div>
            <b-popover :target="'popover-target_' + item.order_id" trigger="hover" title="订单状态">
              <div v-for="(process_item, index) in item.process"
                style="padding: 3px 0;display: flex;align-items: center;justify-content: space-between;" :key="index">
                <span style="margin-right: 15px;">{{ process_item.txt }}</span>
                <span>{{ process_item.time }}</span>
              </div>
            </b-popover>
          </template>
          <template v-slot:fee="{ item }">
            <div style="font-weight: 600;height: 100%;display: flex;">{{ item.fee / 100 }}元
            </div>
          </template>

          <template v-slot:footer="{ item }">
            <div style="padding: 10px;text-align: left;display: flex;border-top: 1px solid #f1f1f1;">
              <div style="font-weight: 600;font-size: 12px;">{{ item.selectedName }}</div>
              <div style="font-size: 12px;margin-left:120px;">x1</div>
              <div style="font-size: 12px;margin-left:50px;">{{ item.fee / 100 }}元</div>
              <div style="font-size: 12px;margin-left:50px;">{{ item.goods_name }}</div>

            </div>

            <div style="padding: 10px;text-align: left;border-top: 1px solid #eeeeee;">
              <div>
                <div style="font-size: 12px;">用户备注：{{ item.remarks.content || '无' }}</div>
                <div style="display: flex;margin-top: 8px;">
                  <img @click="previewImages(item)" v-for="(item, index) in item.remarks.images"
                    style="width: 80px;height: 80px;margin-right: 10px;cursor: pointer;border-radius: 3px;" :key="index"
                    :src="item" alt="">
                </div>
              </div>
            </div>


            <div style="padding: 10px;text-align: left;border-top: 1px solid #eeeeee;">
              <div style="display: flex;font-size: 12px;">
                <div style="min-width: 60px;height: 100%;">商家备注：</div>
                <div><span style="color: #666666;margin-right: 4px;">{{ item.merchant_remarks }} </span>
                  <span @click="order_remarks_merchant(item)" style="color: #206bc4;cursor: pointer;">{{
                    item.merchant_remarks ? '(修改备注)' : '(添加备注)' }}</span>
                </div>
              </div>
            </div>




            <div v-if="item.status === 3"
              style="padding: 10px;text-align: left;display: flex;border-top: 1px solid #eeeeee;justify-content: space-between;">
              <div>
                <div>
                  <div style="font-size: 12px;">取消原因：
                    {{ item.reject_reason?'(商家) ' +item.reject_reason :item.reasons?'(用户) '+item.reasons :'无'}}
                  </div>
                </div>
              </div>
            </div>

            <div
              style="padding: 10px;text-align: left;display: flex;border-top: 1px solid #eeeeee;justify-content: space-between;">
              <div style="display: flex;font-size: 12px;">
                <div style="min-width: 60px;height: 100%;">服务人员：</div>
                <div>
                  <div style="font-size: 12px;">{{ item.sex ==1?'男':item.sex==2?'女':'不限'}}</div>
                </div>
              </div>
              <div style="display: flex;">
                <div @click="rejectOrder(item)" v-if="item.status == 0" class="btn2" style="margin-right: 10px;">
                  拒绝订单</div>
                <div v-if="item.status == 0" @click="confirmOrder(item)" class="mybtn" style="margin-right: 10px;">确认接单
                </div>

                <div v-if="item.status == 1" @click="doneOrder(item)" class="mybtn">
                  完成确认</div>
              </div>

            </div>
          </template>
        </Table>


        <!-- <div v-if="orderList.length == 0 && !showLoading"
          style="display: flex;align-items: center;justify-content: center;padding: 15px 0;background-color: #fff;font-size: 14px;">
          暂无订单
        </div> -->

      </div>
      <div v-if="showLoading"
        style="display: flex;align-items: center;justify-content: center;padding: 20px 0;background-color: #fff;"><img
          style="margin-right: 5px;" src="../assets/loading.gif" alt="">加载中..</div>
    </div>
    <Modal ref="confirmOrder" @confirm="confirmOrder_submit"></Modal>
    <Modal ref="doneOrder" @confirm="doneOrder_submit"></Modal>
    <Modal ref="rejectOrder" @confirm="rejectOrder_submit">
      <template v-slot:item="">
        <textarea v-model="reject_reason" style="width: 100%;" placeholder="（选填）" name="" id="" cols="30"
          rows="5"></textarea>
        <div style="display: flex;align-items: center;;margin-top: 5px;">
          <div @click="changeModalInputValue(item)" v-for="(item, index) in reject_reason_common" :key="index"
            style="padding: 5px 10px;background-color: #eeeeee;border-radius: 5px;font-size: 13px;cursor: pointer;margin-right: 5px;">
            {{ item }}</div>
        </div>
      </template>
    </Modal>



    <!-- 
    <template>
      <div>
        <b-modal @ok="modal2_ok" id="modal2" title="完成确认" ok-title="确定" cancel-title="取消">
          是否对 订单号{{ order_id }} 进行完成确认?
        </b-modal>
      </div>
    </template> -->

    <template>
      <div>
        <b-modal @ok="modal3_ok" id="modal3" title="拒绝原因" ok-title="确定" cancel-title="不取消">
          <textarea v-model="reject_reason" style="width: 100%;" placeholder="（选填）" name="" id="" cols="30"
            rows="5"></textarea>
          <div style="display: flex;align-items: center;;margin-top: 5px;">
            <div @click="changeModalInputValue(item)" v-for="(item, index) in reject_reason_common" :key="index"
              style="padding: 5px 10px;background-color: #eeeeee;border-radius: 5px;font-size: 13px;cursor: pointer;margin-right: 5px;">
              {{ item }}</div>
          </div>
        </b-modal>
      </div>
    </template>

    <!-- We specify the same container as the trigger button, so that popover is close to button -->
    <template>
      <Amap ref="Amap"
        style="width: 68vw;height: 92vh;position: fixed;left: 0;right: 0;z-index: 52555;top: 0;bottom: 0;margin: auto;">
        <template v-slot:content="">
          <div
            style="position: absolute;top: 40px;left: 0;margin: auto;border-radius: 3px;padding: 8px 10px;width: 28%;display: flex;flex-direction: column;align-items: start;line-height: 30px;font-size: 14.5px;background-color: #ffffff;text-align: left;">
            <div style="font-weight: 600;font-size: 13px;">#{{ order_mapData.order_id }}
              {{ order_mapData.selectedName }}
            </div>
            <div>联系人：{{ order_mapData.fullName }}</div>
            <div>联系电话：{{ order_mapData.phone }}</div>
            <div>订单金额：{{ order_mapData.fee / 100 }}元</div>
            <div style="">下单时间：{{ order_mapData.createTimeTxt }}</div>
            <div>地址：{{ order_mapData.fullAddress}}</div>
            <div>备注：{{ order_mapData.remarksContent }}</div>
          </div>
        </template>
      </Amap>
    </template>


    <PreviewImages v-model="showPreviewImages" :src="preview_images"></PreviewImages>

    <Modal ref="order_remarks_merchant" @confirm="order_remarks_merchant_submit"></Modal>

  </div>
</template>
<script>
  const utils = require('../utils/utils')
  import PreviewImages from '../components/PreviewImages/PreviewImages'
  import {
    BModal
  } from 'bootstrap-vue'
  import AddressPicker from '../components/AddressPicker/AddressPicker'
  import Table from '../components/Table/Table'
  import Modal from '../components/Modal/Modal'
  import Amap from '../components/Amap/Amap'
  import Select from '../components/Select/Select'

  export default {
    name: 'Order',
    components: {
      'b-modal': BModal,
      PreviewImages,
      AddressPicker,
      Table,
      Modal,
      Amap,
      Select
    },
    props: ['parentData'],
    data() {
      return {
        items: [],
        tab: [{
            name: '全部订单',
            status: 10,
            count: 0
          },
          {
            name: '待处理',
            status: 0,
            count: 0
          },
          {
            name: '进行中',
            status: 1,
            count: 0
          },
          {
            name: '已取消',
            status: 3,
            count: 0
          },
          {
            name: '已完成',
            status: 2,
            count: 0
          }
        ],
        selected: 0,
        orderList: [],
        showLoading: false,

        order_id: '',
        showPreviewImages: false,
        reject_reason: '',
        reject_reason_common: ['商家无法接单', '不在服务区域', '电话联系不上'],
        preview_images: '',
        fields: [{
            key: 'fullName',
            label: '联系人',
            width: '80px'
          },
          {
            key: 'phone',
            label: '联系电话',
            width: '150px'
          },
          {
            key: 'phoneNumber',
            label: '下单用户',
            width: '150px'
          },
          {
            key: 'address',
            label: '服务地址',
            width: ''
          },
          {
            key: 'appointmentTime',
            label: '预约时间',
            width: '120px'
          },
          {
            key: 'fee',
            label: '订单金额',
            width: '100px'
          },
          {
            key: 'status',
            label: '订单状态',
            width: '120px'
          }
        ],
        order_mapData: {},
        mapShow: false,
        selected_cities: null,
        cities: [],
        searchValue: '',
        loadAll: false,
        businesses_phone: ''
      };
    },
    mounted() {
      // 组件挂载完成回调，处理逻辑
      console.log('父组件数据', this.parentData)
      if (this.parentData.order_id) {
        this.getOrderList({
          order_id: this.parentData.order_id
        })
        console.log('筛选订单id', this.parentData.order_id)
      } else {
        this.getOrderList()
      }


    },
    methods: {
      popoverShow() {
        this.$refs.popover.show()
      },
      tabClick(index) {
        this.selected = index
        this.loadAll = false
        this.getOrderList()
      },
      /**获取合作商家列表*/
      table_scrollEvent() {
        console.log('触发事件')
        this.$refs.orderList.showLoading()
        this.getOrderList({
          connect: true
        })
      },
      citySelectChange(item) {
        console.log('城市', this.selected_cities)
        this.loadAll = false
        this.getOrderList()
      },
      searchInput(e) {
        if (!this.searchValue) {
          this.getOrderList()
        }
      },
      search() {
        this.getOrderList()
      },
      getOrderList(params = {}) {
        let {
          connect = false,
            order_id = false,
            city = this.selected_cities,
            key = this.searchValue
        } = params;
        console.log('getOrderList', connect,
          order_id,
          city,
          key)

        if (!connect) {
          this.orderList = [];
        }
        let selected = this.selected;
        let status = this.tab[this.selected].status;
        let start_id = this.orderList.length > 0 ? this.orderList[this.orderList.length - 1].order_id : null;
        let count = 50;
        this.post('orderList_get', {
          status,
          start_id,
          count,
          city: city,
          key: key,
          order_id: order_id
        }).then(({
          list,
          count,
          cities
        }) => {
          list.forEach(element => {
            element.address = JSON.parse(element.address)
            element.remarks = JSON.parse(element.remarks)
            element.createTimeTxt = utils.formatTime(element.createTime, 'year-month-day hour:minute')
            element.process = JSON.parse(element.process)
          });
          setTimeout(() => {
            if (selected !== this.selected) return false;
            this.tab[0].count = 0
            this.tab.forEach(item => {
              item.count = 0
              count.forEach(element => {
                if (element.status == item.status) {
                  this.tab[0].count += element.count
                  item.count = element.count
                }
              })
            })
            if (!connect) {
              this.orderList = list
            } else {
              if (list.length > 0) {
                console.log('拼接查询', list.length, this.orderList[this.orderList.length - 1].order_id, list[0]
                  .order_id)
                //拼接
                if (this.orderList[this.orderList.length - 1].order_id == list[0].order_id) {
                  list.splice(0, 1)
                  this.orderList = this.orderList.concat(list)
                }
              } else {
                //已加载全部
                console.log('已经加载全部')
                this.loadAll = true
              }
            }
            this.cities = cities
          }, 100);
        })
      },


      order_remarks_merchant(item) {
        this.$refs.order_remarks_merchant.show({
          type: 'form',
          title: '备注',
          data: [{
              name: 'order_id',
              label: '编码',
              value: item.order_id,
              required: true,
              disabled: true,
              hide: true
            },
            {
              name: 'merchant_remarks',
              width: '100%',
              label: '备注',
              value: item.merchant_remarks,
              placeholder: '',
              required: true,
              type: 'textarea',
              hideLabel: true
            }
          ]
        })
      },
      order_remarks_merchant_submit(item) {
        let order_id = item.order_id;
        let merchant_remarks = item.merchant_remarks;
        this.post('order_remarks_merchant', {
          order_id,
          merchant_remarks
        }).then(res => {
          this.$toast({
            title: '操作成功'
          })
          this.tabClick(this.selected)
        })
      },



      confirmOrder(item) {
        this.$refs.confirmOrder.show({
          type: 'query',
          title: '接单确认',
          content: '是否确认接单？',
          data: [{
            name: 'order_id',
            label: '编码',
            value: item.order_id,
            required: true,
            disabled: true
          }]
        })
      },
      confirmOrder_submit(item) {
        let order_id = item.order_id;
        this.post('order_payConfirm', {
          order_id
        }).then(({
          status
        }) => {
          this.$toast({
            title: '操作成功'
          })
          this.getOrderList()
        })
      },



      doneOrder(item) {
        this.$refs.doneOrder.show({
          type: 'query',
          title: '完成确认',
          content: '是否确认完成？',
          data: [{
            name: 'order_id',
            label: '编码',
            value: item.order_id,
            required: true,
            disabled: true
          }]
        })
      },
      doneOrder_submit(item) {
        let order_id = item.order_id;
        this.post('order_doneConfirm', {
          order_id
        }).then(({
          status
        }) => {
          this.$toast({
            title: '操作成功'
          })
          this.getOrderList()
        })
      },



      rejectOrder(item) {
        console.log('店家拒绝')
        this.$refs.rejectOrder.show({
          type: 'form',
          title: '拒绝订单',
          data: [{
            name: 'order_id',
            label: '编码',
            value: item.order_id,
            required: true,
            disabled: true,
            hide: true
          }]
        })
      },

      rejectOrder_submit(item) {
        let order_id = item.order_id;
        let reject_reason = this.reject_reason || '商家关闭订单';
        this.post('order_reject', {
          order_id,
          reject_reason
        }).then(({
          status
        }) => {
          this.$toast({
            title: '操作成功'
          })
          this.getOrderList()
        })
      },
      changeModalInputValue(e) {
        console.log(e)
        this.reject_reason = e
      },
      previewImages(e) {
        this.showPreviewImages = true
        this.preview_images = [e]
      },
      showMap(item) {

        item.fullAddress = item.address.province + item.address.city + item.address.district + item.address.street +
          item.address.street_number;
        item.remarksContent = item.remarks.content;
        console.log(item)
        this.order_mapData = item;
        this.$refs.Amap.setCenter([item.address.location.longitude, item.address.location.latitude])
        let markers = [{
          location: [item.address.location.longitude, item.address.location.latitude],
          data: {}
        }]
        this.$refs.Amap.setMarkers(markers)
        this.$refs.Amap.show()
        // this.$refs.Amap.initMap({ location: [item.address.location.longitude, item.address.location.latitude],markers:[{location: [item.address.location.longitude, item.address.location.latitude], content:'国艺堂养生馆<br>0751-87920143'}] })
      },

    }
  }
</script>
<style scoped>
  .wrapper {
    color: #333;
    background-color: #fff;
    border-radius: 3px;
    border: 1px solid #dddddd;
  }

  .header {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dddddd;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
    font-size: 12.5px;
    font-family: "Microsoft YaHei", SimSun;
    display: flex;
    justify-content: space-between;
  }


  .tab {
    background-color: white;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    user-select: none;

  }

  .tab-select {
    color: white;
    background-color: #1f68be;
  }

  .tab:hover {
    background: #e6e6e6;
    color: black;
  }

  .tab:active {
    background: #e6e6e6;
    color: black;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }

  .tab-border-left {
    border-left: 1px solid #cccccc;
  }

  .mybtn {
    background-color: #2c72b0;
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    color: #ffffff;
    user-select: none;
    border-radius: 3px;
  }

  .mybtn:hover {
    background-color: #286090;
    color: #ffffff;
  }

  .mybtn:active {
    background-color: #1d5280;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }


  .btn2 {
    background-color: #e8e8e8;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    padding: 5px 10px;
    font-size: 11.5px;
    cursor: pointer;
    touch-action: manipulation;
    border: 1px solid #555555;
    color: #000000;
    border-radius: 3px;
    user-select: none;
  }

  .btn2:hover {
    background-color: #cccccc;
  }

  .btn2:active {
    background-color: #aaaaaa;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }

  input {
    outline: none;
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
    border: 1px solid #cccccc;
  }

  input:focus {
    border: 1px solid rgb(56, 115, 192) !important;
  }
</style>