<template>
    <div class="navbara">
        <b-navbar toggleable="lg" type="dark" style="padding: 0 0 0 15px;">
            <b-navbar-brand href="#" style="font-weight: 600;font-size: 22px;">服务商系统</b-navbar-brand>
            <div
                style="display: flex;float: right;height: 52px;align-items: center;flex: 1;width: 100%;justify-content: flex-end;">
                <div class="navItem" @click="exit">
                    <img src="../assets/tuichudenglu.png" style="width:14px;height:14px;margin-right: 5px;opacity: 0.9;"
                        class="d-inline-block align-top" alt="Kitten">
                    <div>退出</div>
                </div>
            </div>
        </b-navbar>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    props: {},
    mixins: [],
    data() {
        return {

        };
    },
    mounted() {
        // 组件挂载完成回调，处理逻辑
    },
    methods:{
        exit(){
            this.$store.set('token',null)
            this.$router.push('/login')
        }
    }
}
</script>
<style>
.navbara {
    background: #206bc4;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    overflow: auto;
    z-index: 888;
}

.navItem {
    font-weight: 500;
    font-size: 14px;
    color: white;
    padding: 0 17px;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
}

.navItem:hover {
    font-weight: 500;
    font-size: 14px;
    color: white;
    padding: 0 17px;
    background: #1d60b0;
    cursor: pointer;
    height: 100%;
    display: flex;
    align-items: center;
}
</style>