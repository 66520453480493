<template>
  <div id="index">
    <navbar style="z-index:8888;" />
    <Sidebar />
  </div>
</template>
  
<script>
import Navbar from '../../components/Navbar.vue'
import Sidebar from '../../components/Sidebar.vue'
import COS from 'cos-js-sdk-v5';


export default {
  name: 'App',
  components: {
    Navbar, Sidebar
  },
  mounted() {
    // this.$router.push('/login')
    console.log('页面加载', this.$vuexstore)
    this.post('getCosConfig').then(async res => {
      console.log('cos数据', res)
      await this.$store.set('tencent_cos_config', res.tencentCosConfig)
      if (res.tencentCosConfig) {
        let { secretId, secretKey, Bucket, Region } = res.tencentCosConfig;
        if (secretId && secretKey && Bucket && Region) {
          this.$cos.cos = new COS({
            SecretId: res.tencentCosConfig.secretId,
            SecretKey: res.tencentCosConfig.secretKey,
          })
          this.$cos.Bucket = Bucket
          this.$cos.Region = Region
          console.log(this.$cos)
        }
      }
    })

  },
  data() {
    return {
      isLogin: this.$store.get('token')
    }
  }
}
</script>
  
<style>
#index {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 10px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1.5px;
  background: #e2e2e2;
}

::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
  background: #9e9e9e;
  opacity: 0;
}

::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  /* box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
      border-radius: 10px;
      background: #ffffff00; */
  opacity: 0;
}
</style>
  