<template>
    <div style="display: flex;align-items: center;">
        <b-form-select v-model="selectedProvince" placeholder="Select an option" :options="provinces" @change="updateCities"
            text-field="name" value-field="code" style="margin-right: 5px;">
            <template #first>
                <b-form-select-option :value="null" disabled>-- 选择省份 --</b-form-select-option>
            </template>
        </b-form-select>
        <b-form-select style="" v-model="selectedCity" :options="cities" text-field="name" value-field="code">
            <template #first>
                <b-form-select-option :value="null" disabled>-- 选择城市 --</b-form-select-option>
            </template>
        </b-form-select>
    </div>
</template>
    
<script>
const { provinces, cities } = require('./data.json')
export default {
    props: {
        value: {
            type: Array,
            default: []
        }
    },
    mounted() {
        console.log('mounted address', this.value)
    },
    data() {
        return {
            selectedProvince: null,
            selectedCity: null,
            selectedProvince_name: '',
            selectedCity_name: '',
            provinces: provinces,
            all_cities: cities,
            cities: [],
        };
    },
    methods: {
        updateCities() {
            this.selectedCity = '';
        },
    },
    watch: {
        selectedProvince(code, name) {
            console.log(code, name)
            this.selectedCity = '';
            let cities = [];
            for (let item of this.all_cities) {
                if (item.provinceCode == code) {
                    cities.push(item)
                }
            }
            this.cities = cities;
        },
        selectedCity() {
            this.selectedProvince_name = this.provinces.find(item => item.code === this.selectedProvince).name;
            this.selectedCity_name = this.cities.find(item => item.code === this.selectedCity).name;
            console.log(this.selectedProvince_name, this.selectedCity_name)
            if (this.selectedProvince_name && this.selectedCity_name) {
                this.array = [this.selectedProvince_name, this.selectedCity_name]
            } else {
                this.array = []
            }
            this.$emit('input',this.array)
        },

    }
};
</script>