<template>
    <div class="main" style="min-width: 110px;">
        <div class="select" :class="show ? 'select-hover' : ''">
            <div @click="showDropdown" :class="{ 'todown': show }">
                <span style="padding-left: 10px;">{{text}}</span>
                <span style="height: 30px;" class="select2" :class="{ 'select2-icon': !show }">
                    <b :class="{ 'select2-focus': show }"></b>
                </span>
            </div>
            <div v-show="show" style="width: 100%;padding:4px 3px;box-sizing: border-box;">
                <div style="background-color: #ffffff;overflow-y: scroll;max-height: 50vh;">
                    <div>
                        <input v-if="showSearch" @keyup="searchInputKeyup" style="border-radius: 4px;height: 30px;" />
                    </div>
                    <div v-if="showAll"
                        :style="{ 'background-color': value == null ? '#206bc4' : '#ffffff', 'color': value == null ? '#ffffff' : '#000000' }">
                        <div @click="selected_All()" class="option">
                            <span style="padding-left: 10px;">{{ placeholder }}</span>
                        </div>
                    </div>
                    <div v-for="(item, index) in options" :key="index" v-show="!item.hide"
                        :style="{ 'background-color': item.value === value ? '#206bc4' : '#ffffff', 'color': item.value === value ? '#fff' : '#000' }">
                        <div @click="tabItem(item)" class="option">
                            <span style="padding-left: 10px;">{{ item.text }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: String,
                default: null
            },
            options: {
                type: Array,
                default: []
            },
            placeholder: {
                type: String,
                default: '全部城市'
            },
            showAll: {
                type: Boolean,
                default: true
            },
            showSearch: {
                type: Boolean,
                default: true
            },
        },
        data() {
            return {
                show: false,
                selected: 0,
                selectedAll: true,
                text: ''
            };
        },
        mounted() {
            console.log('Select mounted', this.options, this.value)
            console.log('选中cddent', )
            let current = this.options.filter(item => item.value == this.value);
            console.log('选中current', current)
            if (current.length > 0) {
                this.text = current[0].text
            }
            if (this.value && this.value.length > 0) {
                this.selectedAll = false
            }
        },
        watch: {
            value(newValue, o) {
                this.$emit('change', newValue, oldValue)
            },
            // options(newValue) {
            //     console.log('options值改变', newValue)
            //     if (newValue && newValue.length > 0) {
            //         if (!this.showAll) {
            //             this.tabItem(newValue[0])
            //         } else {
            //             // this.selected_All()
            //         }

            //     }
            // }
        },
        methods: {
            showDropdown() {
                this.show = !this.show
            },
            tabItem(item) {
                console.log('选中改变', item.text)
                this.value = item.value
                this.text = item.text
                this.show = false
                this.selectedAll = false
                this.$emit('input', item.value)
                this.$emit('change', item)
            },
            selected_All() {
                console.log('selected_All')
                this.selectedAll = true
                this.show = false
                this.value = null
                this.selected = 0;
                this.text = ''
                this.$emit('input', null)
                this.$emit('change', null)
            },
            searchInputKeyup(e) {
                console.log(e.target.value)
                this.options.forEach(element => {
                    element.hide = element.text.indexOf(e.target.value) == -1
                });
                this.$forceUpdate()
            }
        },

    };
</script>

<style scoped>
    .main {
        height: 30px;
        padding: 5px 40px 5px 10px;
        font-size: 13px;
        line-height: 30px;
        background: #ffffff;
        position: relative;
        border-radius: 4px;
    }

    .select {
        position: absolute;
        left: 0;
        top: 0;
        min-height: 100%;
        display: flex;
        border: 1px solid #aaaaaa;
        border-radius: 4px;
        flex-direction: column;
        background-color: #ffffff;
        text-align: left;
        user-select: none;
        overflow: hidden;
        width: 100%;
    }

    .select-hover {
        border: 1px solid rgb(56, 115, 192) !important;
    }

    .select2 {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        font-size: 13px;
        white-space: nowrap;
        color: #444;
        user-select: none;
        line-height: 30px;
        box-sizing: border-box;
        list-style: none;
        border: none;
        outline: 0 !important;
        padding: 0;
        margin: 0;
        touch-action: manipulation;
        display: inline-block;
        width: 18px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 4px 4px 0;
        /* background: #ccc; */
    }

    b {
        -webkit-text-size-adjust: 100%;
        font: 14px Helvetica Neue, Helvetica, PingFang SC, \5FAE\8F6F\96C5\9ED1, Tahoma, Arial, sans-serif;
        -webkit-tap-highlight-color: transparent;
        font-size: 13px;
        white-space: nowrap;
        color: #444;
        user-select: none;
        line-height: 30px;
        box-sizing: border-box;
        font-family: "Microsoft YaHei", SimSun;
        list-style: none;
        border: none;
        outline: 0 !important;
        padding: 0;
        margin: 0;
        touch-action: manipulation;
        font-weight: 700;
        display: block;
        width: 100%;
        height: 100%;
        background: url(./img/select2.png) 0 1px no-repeat;
        background-image: url(./img/select2.png) !important;
        background-repeat: no-repeat !important;
        background-size: 60px 40px !important;
    }

    .select2-icon {
        background-image: linear-gradient(to top, #ccc 0, #eee 60%);
        border-left: 1px solid #aaa;
    }

    .todown {
        background-image: linear-gradient(to top, #fff 0, #eee 50%);
    }


    .select2-focus {
        background-position: -18px 1px;
    }



    input {
        outline: none;
        height: 40px;
        padding: 5px 4px;
        font-size: 12px;
        line-height: 1.5;
        border-radius: 1px;
        border: 1px solid #aaaaaa;
    }

    .option {
        background-color: none;
        cursor: pointer;
        line-height: 34px;
        margin-top: 3px;
    }

    .option:hover {
        background-color: #206bc4;
        color: white;
    }
</style>