<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  import Navbar from './components/Navbar.vue'
  import Sidebar from './components/Sidebar.vue'


  export default {
    name: 'App',
    components: {
      Navbar,
      Sidebar
    },
    mounted() {

    },
    data() {
      return {
        isLogin: this.$store.get('token')
      }
    }
  }
</script>

<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  ::-webkit-scrollbar {
    /*滚动条整体样式*/
    width: 10px;
    /*高宽分别对应横竖滚动条的尺寸*/
    height: 1.5px;
    background: #e2e2e2;
  }

  ::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
    background: #9e9e9e;
    opacity: 0;
  }

  ::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    /* box-shadow: inset 0 0 5px rgba(87, 175, 187, 0.1);
	border-radius: 10px;
	background: #ffffff00; */
    opacity: 0;
  }
</style>